import { StatusCodes } from 'http-status-codes';
import PlatformError from './platform-error';

export class UserTokenError extends PlatformError {
    constructor(missingProp: string) {
        super({
            message: `can not receive unit token without ${missingProp}`,
            obj: {
                status: StatusCodes.UNAUTHORIZED
            }
        });
    }
}
export default UserTokenError;
