import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import getConfig from 'next/config';
import { logger } from '@finance/shared/dist/utils/logger';
import { useMemo } from 'react';
import { signOutMessage } from './lib/client/services/postMessageService';
import { signOut } from 'next-auth/react';
import { trackError } from 'lib/error.utils';

export default function useApollo() {
    return useMemo(() => createApolloClient(), []);
}

const createApolloClient = () => {
    const { publicRuntimeConfig } = getConfig();
    // Log any GraphQL errors or network error that occurred
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(error => {
                const {
                    message,
                    locations,
                    path,
                    extensions: { code }
                } = error;
                logger.error(
                    {
                        message,
                        locations,
                        path
                    },
                    `[GraphQL error] ${message}`
                );
                trackError({
                    error,
                    message: `GraphQL error`,
                    context: {
                        message,
                        locations,
                        path,
                        code
                    }
                });
                if (code === 'UNAUTHENTICATED') {
                    // redirect to login page
                    signOutMessage();
                    signOut();
                }
            });

        if (networkError) logger.error(`[Network error]: ${networkError}`);
    });

    const httpLink = createHttpLink({
        uri: '/api/graphql'
    });

    const link = ApolloLink.from([errorLink, httpLink]);

    const cache = new InMemoryCache({
        typePolicies: {
            RecurringCreditAchPaymentAttributes: {
                merge: true
            },
            TransactionAttributes: {
                merge: true
            },
            AchTransactionAttributes: {
                merge: true
            },
            PurchaseTransactionAttributes: {
                merge: true
            },
            TransactionTags: {
                merge: true
            },
            UIPreference: {
                keyFields: ['key']
            }
        }
    });

    return new ApolloClient({
        uri: `${publicRuntimeConfig.serverBaseUrl}/api/graphql`,
        cache,
        link: link,
        connectToDevTools: true
    });
};
