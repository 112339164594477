import crypto from 'crypto';
import platformConfig from '../config/platformConfig';

const inputEncoding = 'utf-8';
const outputEncoding = 'hex';
// IV length is 12 bytes for GCM
export const initializationVector: Buffer = platformConfig.crypto.iv ? Buffer.from(platformConfig.crypto.iv, inputEncoding) : crypto.randomBytes(12);
// Key length is 32 bytes for AES-256
export const key: Buffer = platformConfig.crypto.key ? Buffer.from(platformConfig.crypto.key, inputEncoding) : crypto.randomBytes(32);

function generateAdvancedKey(...values: string[]): Buffer {
    const advancedKey = key.toString(outputEncoding) + (values ? values.join('') : '');
    return Buffer.from(crypto.createHash('sha256').update(advancedKey).digest(outputEncoding), outputEncoding);
}

export function encrypt(data: string, ...keyValues: string[]): string {
    const advancedKey = generateAdvancedKey(...keyValues);
    const cipher = crypto.createCipheriv(platformConfig.crypto.algorithm, advancedKey, initializationVector) as crypto.CipherGCM;
    const encrypted = Buffer.concat([cipher.update(data, inputEncoding), cipher.final()]);
    const authTag = cipher.getAuthTag();

    const ivHex = initializationVector.toString(outputEncoding);
    const tagHex = authTag.toString(outputEncoding);
    const encryptedDataHex = encrypted.toString(outputEncoding);
    return `${ivHex}:${tagHex}:${encryptedDataHex}`;
}

export function decrypt(encryptedData: string, ...keyValues: string[]): string {
    const advancedKey = generateAdvancedKey(...keyValues);
    const [ivHex, tagHex, encryptedDataHex] = encryptedData.split(':');

    const decipher = crypto.createDecipheriv(platformConfig.crypto.algorithm, advancedKey, Buffer.from(ivHex, outputEncoding)) as crypto.DecipherGCM;
    decipher.setAuthTag(Buffer.from(tagHex, outputEncoding));
    return decipher.update(encryptedDataHex, outputEncoding, inputEncoding) + decipher.final(inputEncoding);
}
