import { Db } from 'mongodb';
import { FintechCustomerDTO } from '../../models/customer';
import { AbstractMongoDataService } from './abstract-mongo-data-service';
import { FintechCustomerDataService, WithId } from '../../../types/dal-types';
import { MongoCollectionsEnum } from '../../../types';

export class CustomerMongoDataService extends AbstractMongoDataService<FintechCustomerDTO> implements FintechCustomerDataService<FintechCustomerDTO> {
    constructor(db: Db) {
        super(db);
    }

    getCollectionName(): MongoCollectionsEnum {
        return MongoCollectionsEnum.CUSTOMERS;
    }

    async findCustomerByHbMemberId(hbMemberId: string): Promise<WithId<FintechCustomerDTO> | null> {
        return this.collection.findOne({ 'tags.memberId': hbMemberId });
    }

    async findCustomerByUnitCustomerId(customerId: string): Promise<WithId<FintechCustomerDTO> | null> {
        return await this.collection.findOne({ unitCustomerId: customerId });
    }
}
