import { StatusCodes } from 'http-status-codes';
import PlatformError from './platform-error';

export class MissingUnitCustomerTokenError extends PlatformError {
    constructor(customerId: string) {
        super({
            message: `customer ${customerId} is missing a token`,
            obj: {
                status: StatusCodes.UNAUTHORIZED
            }
        });
    }
}
export default MissingUnitCustomerTokenError;
