import { PlaidInstitutionAuth } from '../dal/models';
import { FintechPlaidInstitutionAuthDataService, WithId } from '../types/dal-types';
import { decrypt, encrypt } from '../utils/crypto-utils';
import { PlatformError } from '../errors';
import PlaidApi from '../api/plaid/plaid-api';
import { JWKPublicKey, Products, TransactionsSyncResponse } from 'plaid';
import { PlaidInstitutionAuthStatus } from '../types';
import { ObjectId } from 'bson';
import { AccountsGetResponse, RemovedTransaction, Transaction } from 'plaid/api';
import { PlaidTransactionsResponse } from '../types/plaid.types';
import { logger } from '@finance/shared';

export class PlaidInstitutionAuthDataService {
    readonly dataService: FintechPlaidInstitutionAuthDataService<PlaidInstitutionAuth>;
    private api: PlaidApi;

    constructor({ dataService }: { dataService: FintechPlaidInstitutionAuthDataService<PlaidInstitutionAuth> }) {
        this.dataService = dataService;
        this.api = new PlaidApi();
    }

    async findPlaidInstitutionAuthByItemId(itemId: string): Promise<PlaidInstitutionAuth | null> {
        const auth = await this.dataService.findPlaidInstitutionAuthByItemId(itemId);
        if (auth) {
            auth.accessToken = decrypt(auth.accessToken, auth.institutionId);
        }
        return auth;
    }

    async findPlaidInstitutionAuthsByHbMemberId(hbMemberId: string): Promise<PlaidInstitutionAuth[]> {
        const auths = await this.dataService.findPlaidInstitutionAuthsByHbMemberId(hbMemberId);

        for (const auth of auths) {
            auth.accessToken = decrypt(auth.accessToken, auth.institutionId);
        }
        return auths;
    }

    async findPlaidInstitutionAuthByHbMemberIdAndInstitutionId(
        hbMemberId: string,
        institutionId: string
    ): Promise<WithId<PlaidInstitutionAuth> | null> {
        const auth = await this.dataService.findPlaidInstitutionAuthByHbMemberIdAndInstitutionId(hbMemberId, institutionId);
        if (auth) {
            auth.accessToken = decrypt(auth.accessToken, auth.institutionId);
        }
        return auth;
    }

    async create(entity: PlaidInstitutionAuth): Promise<WithId<PlaidInstitutionAuth>> {
        try {
            // copy the entity to avoid modifying the original argument object.
            const copy = { ...entity };
            copy.accessToken = encrypt(entity.accessToken, entity.institutionId);
            return <WithId<PlaidInstitutionAuth>>await this.dataService.create(copy);
        } catch (e) {
            throw new PlatformError({
                message: 'Failed creating PlaidInstitutionAuth in db',
                obj: {
                    plaidItemId: entity.itemId,
                    error: e
                }
            });
        }
    }

    async update(entity: PlaidInstitutionAuth): Promise<WithId<PlaidInstitutionAuth>> {
        try {
            const existingItem = await this.findPlaidInstitutionAuthByHbMemberIdAndInstitutionId(
                entity.hbMemberId.toHexString(),
                entity.institutionId
            );
            if (existingItem) {
                const entityCopy = { ...entity };
                entityCopy._id = existingItem._id;
                entityCopy.accessToken = encrypt(entity.accessToken, entity.institutionId);
                const res = <WithId<PlaidInstitutionAuth>>await this.dataService.update(entityCopy);

                if (entity.accessToken !== existingItem.accessToken) {
                    logger.debug('Resetting cursor due to updated access token');
                    await this.dataService.unset(existingItem._id, ['cursor']);
                }

                if (existingItem.status !== PlaidInstitutionAuthStatus.Deleted && entity.accessToken !== existingItem.accessToken) {
                    logger.debug('Deleting item from Plaid due to updated access token');
                    await this.api.deleteItem(existingItem.accessToken);
                }
                return res;
            } else {
                return <WithId<PlaidInstitutionAuth>>await this.create(entity);
            }
        } catch (e) {
            throw new PlatformError({
                message: 'Failed creating PlaidInstitutionAuth in db',
                obj: {
                    plaidItemId: entity.itemId,
                    status: entity.status,
                    cursor: entity.cursor,
                    error: e
                }
            });
        }
    }

    async readAll() {
        const auths = (await this.dataService.readAll()) || [];

        for (const auth of auths) {
            auth.accessToken = decrypt(auth.accessToken, auth.institutionId);
        }
        return auths;
    }

    async readAllDataAvailableInstitutions(): Promise<WithId<PlaidInstitutionAuth>[]> {
        const auths = (await this.dataService.readAllValidInstitutionAuths()) || [];

        for (const auth of auths) {
            auth.accessToken = decrypt(auth.accessToken, auth.institutionId);
        }
        return auths;
    }

    async createLinkToken({
        userMemberId,
        userEmail,
        plaidProducts
    }: {
        userMemberId: string;
        userEmail: string;
        plaidProducts: Products[];
    }): Promise<string> {
        try {
            return await this.api.createLinkToken({ userMemberId, userEmail, plaidProducts });
        } catch (error) {
            throw new PlatformError({
                message: 'Failed to create link token',
                obj: {
                    userMemberId,
                    userEmail,
                    plaidProducts,
                    error
                }
            });
        }
    }

    async createLinkTokenForReconnect({
        userMemberId,
        userEmail,
        institutionId
    }: {
        userMemberId: string;
        userEmail: string;
        institutionId: string;
    }): Promise<string> {
        const auth = await this.findPlaidInstitutionAuthByHbMemberIdAndInstitutionId(userMemberId, institutionId);
        if (!auth) {
            throw new PlatformError({
                message: 'PlaidInstitutionAuth not found when trying to create link token for reconnect',
                obj: {
                    userMemberId,
                    userEmail,
                    institutionId
                }
            });
        }

        return await this.api.createLinkToken({
            userMemberId,
            userEmail,
            accessToken: auth.accessToken
        });
    }

    async exchangePublicToken({
        token,
        hbMemberId,
        institutionId,
        institutionName,
        activeAccountIds
    }: {
        token: string;
        hbMemberId: ObjectId;
        institutionId: string;
        institutionName: string;
        activeAccountIds: string[];
    }): Promise<boolean> {
        try {
            const logDetails = {
                hbMemberId,
                institutionId,
                institutionName,
                activeAccountIds
            };
            const data = await this.api.exchangePublicToken(token);
            logger.debug(logDetails, 'Exchanged public token');

            // upsert PlaidInstitutionAuth.
            await this.update({
                hbMemberId,
                itemId: data.item_id,
                institutionId,
                institutionName,
                accessToken: data.access_token,
                status: PlaidInstitutionAuthStatus.PendingData,
                activeAccountIds
            });
            logger.debug(
                {
                    ...logDetails,
                    itemId: data.item_id
                },
                'Upsert PlaidInstitutionAuth with pending data status'
            );

            await this.syncTransactions({
                access_token: data.access_token
            });
            logger.debug(
                {
                    ...logDetails,
                    itemId: data.item_id
                },
                'Invoked sync transactions'
            );
            return true;
        } catch (error) {
            throw new PlatformError({
                message: 'Failed to exchange public token',
                obj: {
                    hbMemberId,
                    institutionId,
                    institutionName,
                    activeAccountIds,
                    error
                }
            });
        }
    }

    async getWebhookVerificationKey(key_id: string): Promise<JWKPublicKey> {
        return await this.api.getWebhookVerificationKey(key_id);
    }

    private async syncTransactions({ access_token, cursor }: { access_token: string; cursor?: string }): Promise<TransactionsSyncResponse> {
        return await this.api.syncTransactions({
            access_token,
            cursor
        });
    }

    private async verifiedPlaidInstitutionAuth(itemId: string): Promise<PlaidInstitutionAuth> {
        const auth = await this.findPlaidInstitutionAuthByItemId(itemId);
        if (!auth) {
            throw new PlatformError({
                message: 'PlaidInstitutionAuth not found',
                obj: {
                    itemId
                }
            });
        }

        // plaid sends webhooks for transaction updates when the item is in error state.
        if (auth.status === PlaidInstitutionAuthStatus.Deleted) {
            throw new PlatformError({
                message: 'PlaidInstitutionAuth is mark Deleted',
                obj: {
                    itemId: auth.itemId
                }
            });
        } else if (auth.status !== PlaidInstitutionAuthStatus.Ok) {
            logger.debug({ itemId: auth.itemId, status: auth.status }, 'PlaidInstitutionAuth not in OK status');
        }
        return auth;
    }

    async syncTransactionsForItemWithId(itemId: string): Promise<PlaidTransactionsResponse> {
        const auth = await this.verifiedPlaidInstitutionAuth(itemId);

        let cursor = auth.cursor;
        let hasMore = true;
        let added: Transaction[] = [];
        let modified: Transaction[] = [];
        let removed: RemovedTransaction[] = [];

        while (hasMore) {
            try {
                const res = await this.syncTransactions({
                    access_token: auth.accessToken,
                    cursor
                });
                added = added.concat(res.added);
                modified = modified.concat(res.modified);
                removed = removed.concat(res.removed);
                cursor = res.next_cursor;
                hasMore = res.has_more;
            } catch (e) {
                throw new PlatformError({
                    message: 'Failed to sync transactions',
                    obj: {
                        itemId,
                        cursor,
                        hasMore,
                        error: e
                    }
                });
            }
        }
        return {
            added,
            modified,
            removed,
            nextCursor: cursor as string
        };
    }

    async getInstitutionBalances(itemId: string, accessToken: string): Promise<AccountsGetResponse> {
        try {
            return await this.api.getBalance(accessToken);
        } catch (e) {
            throw new PlatformError({
                message: 'Failed to get balance',
                originalError: PlatformError.isPlatformError(e) ? e : undefined,
                obj: {
                    itemId
                }
            });
        }
    }

    async getInstitutionLogo(institutionId: string): Promise<string | null> {
        return await this.api.getInstitutionLogo(institutionId);
    }

    async deleteItem({ institutionId, hbMemberId }: { institutionId: string; hbMemberId: string }): Promise<string> {
        const auth = await this.findPlaidInstitutionAuthByHbMemberIdAndInstitutionId(hbMemberId, institutionId);
        if (!auth) {
            throw new PlatformError({
                message: 'PlaidInstitutionAuth not found when trying to delete item',
                obj: {
                    institutionId,
                    hbMemberId
                }
            });
        }

        logger.info({ authId: auth._id, itemId: auth.itemId, institutionId, hbMemberId }, 'Deleting item');
        await this.api.deleteItem(auth.accessToken);
        auth.status = PlaidInstitutionAuthStatus.Deleted;
        await this.update(auth);
        logger.info({ authId: auth._id, itemId: auth.itemId, institutionId, hbMemberId }, 'Deleted item');

        return auth.itemId;
    }
}
