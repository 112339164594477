import { FintechAccountDTO } from '../../models/account';
import { AbstractInMemoryDataService } from './abstract-in-memory-data-service';
import { FintechAccountDataService, WithId } from '../../../types/dal-types';
import { logger } from '@finance/shared/dist/utils/logger';

export class AccountInMemoryDataService
    extends AbstractInMemoryDataService<FintechAccountDTO>
    implements FintechAccountDataService<FintechAccountDTO>
{
    public constructor(storage: Record<string, FintechAccountDTO>) {
        super(storage);
        logger.debug({ storage }, 'InMemoryDB: AccountInMemoryDataService.new');
    }

    async findAccounts(customerId: string): Promise<WithId<FintechAccountDTO>[]> {
        const accounts = Object.values(this.storage).filter(account => account.customerId.toHexString() === customerId);
        logger.debug(
            {
                customerId,
                accounts,
                knownCustomerIds: Object.values(this.storage).map(account => account.customerId),
                storage: this.storage
            },
            'InMemoryDB: findAccounts'
        );
        return <WithId<FintechAccountDTO>[]>accounts;
    }

    async findAccountByUnitAccountId(unitAccountId: string): Promise<WithId<FintechAccountDTO> | null> {
        const account = Object.values(this.storage).find(account => account.unitAccountId === unitAccountId);
        logger.debug(
            {
                unitAccountId,
                account,
                knownUnitAccountIds: Object.values(this.storage).map(account => account.unitAccountId),
                storage: this.storage
            },
            'InMemoryDB: findAccountByUnitAccountId'
        );
        if (account) {
            return <WithId<FintechAccountDTO>>account;
        }
        return null;
    }

    async findAccountsByPurpose(customerId: string, purpose: string): Promise<WithId<FintechAccountDTO>[]> {
        const accounts = Object.values(this.storage).filter(
            account => account.customerId.toHexString() === customerId && account.tags.purpose === purpose
        );
        logger.debug(
            {
                customerId,
                purpose,
                accounts,
                storage: this.storage
            },
            'InMemoryDB: findAccountsByPurpose'
        );
        return <WithId<FintechAccountDTO>[]>accounts;
    }
}
