import { Db } from 'mongodb';
import { AbstractMongoDataService } from './abstract-mongo-data-service';
import { FintechEventDataService, WithId } from '../../../types/dal-types';
import { MongoCollectionsEnum } from '../../../types';
import { FintechEventDTO } from '../../models/event';

export class EventMongoDataService extends AbstractMongoDataService<FintechEventDTO> implements FintechEventDataService<FintechEventDTO> {
    constructor(db: Db) {
        super(db);
    }

    getCollectionName(): MongoCollectionsEnum {
        return MongoCollectionsEnum.EVENTS;
    }

    async findEventsByType(eventType: string): Promise<WithId<FintechEventDTO>[]> {
        return await this.collection.find({ type: eventType }).toArray();
    }

    async findEventsByTimeRange(since: Date, until: Date): Promise<WithId<FintechEventDTO>[]> {
        return await this.collection
            .find({
                'attributes.createdAt': {
                    $gte: new Date(since.toISOString()),
                    $lt: new Date(until.toISOString())
                }
            })
            .toArray();
    }
}
