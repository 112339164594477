import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import sharedConfig from '../config/sharedConfig';
import logger from './logger';

export interface AxiosFactoryProps {
    config: AxiosRequestConfig;
    cacheAxios?: boolean;
}

export class AxiosFactory {
    static create({ config, cacheAxios = false }: AxiosFactoryProps): AxiosInstance {
        // Create `axios` instance with pre-configured `axios-cache-interceptor`,
        // unless we are in test env
        let axiosInstance = axios.create(config);

        if (!sharedConfig.env.isTestEnv && cacheAxios) {
            axiosInstance = setupCache(axiosInstance, {
                ttl: sharedConfig.dataSourceCacheTTL
            });
        }

        axiosInstance.interceptors.request.use(onRequest);
        axiosInstance.interceptors.response.use(onResponse);

        return axiosInstance;
    }
}

export default AxiosFactory;

function serializedConfig(config: AxiosRequestConfig) {
    const { baseURL, method, url, data, params } = config;

    return {
        baseURL,
        url,
        method,
        params,
        config: {
            data: data ? Buffer.from(JSON.stringify(data)).toString('base64') : [],
            path: data?.request?.path,
            pathname: data?.request?.pathname,
            search: data?.request?.search
        }
    };
}

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    logger.debug(
        {
            request: serializedConfig(config)
        },
        '[HTTP] Request'
    );
    return config;
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
    const { data, status, statusText } = response;

    logger.debug(
        {
            response: {
                config: serializedConfig(response.config),
                data,
                status,
                statusText
            }
        },
        '[HTTP] Response'
    );
    return response;
};
