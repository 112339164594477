import { DepositAccount, extractUnitError, Unit, UnitError } from '@unit-finance/unit-node-sdk';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import platformConfig from '../../config/platformConfig';
import { Forbidden, MissingUnitCustomerTokenError, UserTokenError, PlatformApiError } from '../../errors';
import { ApiContext, ApplicationTags, CustomerTokenPurposeEnum } from '../../types';
import { AxiosFactory, logger, redis } from '@finance/shared';
import { AxiosFactoryProps } from '@finance/shared/dist/utils/axios-factory';
import { getCustomerTokenKey } from '../../utils';

export type AccessCheckerFn = (context: ApiContext, api: Unit) => boolean | Promise<boolean>;

export type WithAccessControlParams = {
    customChecker?: AccessCheckerFn;
    accountId?: string;
    customerId?: string;
    applicationId?: string;
};

export default class UnitBase {
    private readonly api: Unit;
    protected axios: AxiosInstance;
    protected context?: ApiContext;

    constructor(context?: ApiContext, axiosFactoryProps: Omit<AxiosFactoryProps, 'config'> = {}) {
        this.setContext(context);
        this.axios = this.initAxios(axiosFactoryProps);
        this.api = this.initUnit();
    }

    setContext(context?: ApiContext) {
        this.context = context;
    }

    mergeContext(context: ApiContext, override = false) {
        if (!this.context) {
            this.setContext(context);
        } else {
            const localContext = this.context;
            Object.keys(context).forEach(key => {
                const castedKey = key as keyof ApiContext;
                const selectedValue = override
                    ? context[castedKey] ?? localContext[castedKey]
                    : localContext[castedKey] ?? context[castedKey];
                localContext[castedKey] = selectedValue as
                    | (string & {
                          _id: string;
                          email: string;
                          accountId?: string | undefined;
                          fingerprint?: string | undefined;
                      })
                    | undefined;
            });
        }
    }

    protected initAxios(axiosFactoryProps: Omit<AxiosFactoryProps, 'config'> = {}) {
        const {
            unit: { baseURL, token, demoToken }
        } = platformConfig;

        const unitSandboxBaseUrl = 'https://api.s.unit.sh';
        const isDemoUser = this.context?.user?.isDemo;

        const config: AxiosRequestConfig = {
            baseURL: isDemoUser ? unitSandboxBaseUrl : baseURL,
            headers: {
                Authorization: `Bearer ${isDemoUser ? demoToken : token}`,
                'Content-Type': 'application/vnd.api+json',
                'User-Agent': 'hb-finance-node-sdk'
            }
        };
        const props = {
            cacheAxios: true,
            ...axiosFactoryProps,
            config
        };

        return AxiosFactory.create(props);
    }

    protected initUnit(config?: { axios?: AxiosInstance; token?: string }) {
        const {
            unit: { token: unitToken, demoToken: unitDemoToken }
        } = platformConfig;
        const isDemoUser = this.context?.user?.isDemo;
        const token = config?.token ?? (isDemoUser ? unitDemoToken : unitToken);
        const axios = config?.axios ?? this.axios;
        return new Unit(token, '', { axios });
    }

    protected async defaultAccessChecker({
        customerId,
        accountId,
        applicationId
    }: {
        customerId?: string;
        accountId?: string;
        applicationId?: string;
    }) {
        const resolveCustomerId = async (apiContext: ApiContext) => {
            if (apiContext.customerId) {
                return apiContext.customerId;
            } else if (apiContext.user?._id) {
                const memberId = apiContext.user?._id;
                const { data: customers } = await this.api.customers.list({
                    tags: { memberId }
                });
                if (customers?.length) {
                    const customer = customers.find(customer => (customer?.attributes?.tags as ApplicationTags).memberId === memberId);
                    if (customer) {
                        apiContext.customerId = customer.id;
                        return customer.id;
                    }
                }
            }
        };

        const { context, api } = this;
        if (context) {
            let hasAccess = false;
            const contextCustomerId = await resolveCustomerId(context);
            if (customerId) {
                hasAccess = customerId === contextCustomerId;
            } else if (accountId) {
                const { data: account } = await api.accounts.get(accountId);
                hasAccess = (account as DepositAccount).relationships.customer?.data.id === contextCustomerId;
            } else if (applicationId) {
                const { data: application } = await api.applications.get(applicationId);
                hasAccess = application.relationships.customer?.data.id === contextCustomerId;
            }

            return hasAccess;
        } else {
            return true;
        }
    }

    /**
     * Intentionally bypass access control check in situations there is no customer context to verify its access.
     * For example, when we need to create an application for a customer, we don't have a customer context yet.
     */
    protected withoutAccessControl(): Unit {
        return this.api;
    }

    protected async withAccessControl({ customChecker, accountId, customerId, applicationId }: WithAccessControlParams) {
        let hasAccess;
        const { context, api } = this;

        if (!context) {
            return api;
        }

        if (customChecker) {
            hasAccess = await customChecker(context, api);
        } else {
            hasAccess = await this.defaultAccessChecker({
                accountId,
                customerId,
                applicationId
            });
        }

        if (hasAccess) {
            return api;
        }

        throw new Forbidden();
    }

    protected async withUserToken(customerId?: string) {
        const { context } = this;
        customerId = customerId ?? context?.customerId;
        if (!customerId) {
            throw new UserTokenError('customerId');
        }

        const fingerprint = context?.user?.fingerprint;

        if (!fingerprint) {
            throw new UserTokenError('fingerprint');
        }

        const tokenCacheKey = getCustomerTokenKey(customerId, fingerprint, CustomerTokenPurposeEnum.FullPrivileges);
        logger.trace({ tokenCacheKey }, 'getting cached token');
        const token = await redis.get(tokenCacheKey);

        if (!token) {
            throw new MissingUnitCustomerTokenError(customerId);
        }

        return this.initUnit({ token });
    }

    /**
     * @returns Unit instance with admin token. Use with caution!
     */
    protected dangerouslyWithAdminToken() {
        const {
            unit: { adminToken }
        } = platformConfig;
        if (!adminToken) {
            throw new MissingUnitCustomerTokenError('admin');
        }

        return new Unit(adminToken, '', { axios: this.axios });
    }

    private getFullStackTrace(error: UnitError): string {
        const stackArray = [];
        if (error.stack) {
            stackArray.push(error.stack);
        }

        let limit = 3;
        while (error.underlying && limit-- > 0) {
            if (error.underlying.stack) {
                if (stackArray.length) {
                    stackArray.push('\nUnderlying error: ');
                }

                stackArray.push(error.underlying.stack);
            }
            error = error.underlying;
        }

        return stackArray.join('');
    }

    private serializeError(error: UnitError, logAttributes: Record<string, unknown>): PlatformApiError {
        const unitError = error.underlying ? extractUnitError(error.underlying) : error;
        const underlyingError = unitError.underlying || error.underlying;
        const { method, url, baseURL, data, timeout } = underlyingError?.config || {};
        let status: number | undefined;
        let message = unitError.message || underlyingError?.message || '';
        if (unitError.errors?.length) {
            status = Number(unitError.errors[0].status);
            message += `${message.length ? ': ' : ''}${unitError.errors.map(error => error.title).join(', ')}`;
        }

        return new PlatformApiError({
            message: message || '[Unit] Unexpected error',
            originalError: unitError,
            metadata: {
                ...unitError,
                ...logAttributes,
                apiImpl: this.constructor.name,
                user: this.context?.user?.email,
                accountId: this.context?.user?.accountId,
                config: {
                    method,
                    url,
                    baseURL,
                    data: data ? Buffer.from(data).toString('base64') : '',
                    timeout
                },
                status,
                stack: this.getFullStackTrace(unitError),
                underlying: undefined // Intentionally remove underlying error to avoid circular reference
            },
            status,
            method,
            url,
            baseURL,
            isUnitError: unitError.isUnitError
        });
    }

    handleUnitError({ error, ...logAttributes }: { error: UnitError } & Record<string, unknown>): PlatformApiError {
        return this.serializeError(error, logAttributes);
    }
}
