import Pino, { DestinationStream } from 'pino';
import alsContext from './als';
import sharedConfig from '../config/sharedConfig';

const loggerOptions: Pino.LoggerOptions = {
    level: sharedConfig.logLevel,
    formatters: {
        level(label) {
            return { level: label };
        }
    }
};

if (sharedConfig.env.isDevelopmentEnv) {
    loggerOptions.transport = {
        target: 'pino-pretty',
        options: {
            translateTime: 'SYS:dd-mm-yyyy HH:MM:ss',
            colorize: true
        }
    };
}

export const testLogger: DestinationStream = {
    write: msg => {
        const json = JSON.parse(msg);
        console.log(json); // eslint-disable-line no-console
        return json;
    }
};

export const rawLogger = Pino(loggerOptions, sharedConfig.env.isTestEnv ? testLogger : process.stdout);

export const logger = new Proxy(rawLogger, {
    get(target, property, receiver) {
        const contextualLogger = alsContext?.getStore()?.get('logger');

        target = contextualLogger || target;
        return Reflect.get(target, property, receiver);
    }
});
export default logger;
