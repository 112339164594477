import {
    Configuration,
    CountryCode,
    JWKPublicKey,
    LinkTokenCreateRequest,
    PlaidApi as PlaidClient,
    PlaidEnvironments,
    TransactionsSyncResponse,
    Products,
    AccountsGetResponse
} from 'plaid';
import platformConfig from '../../config/platformConfig';
import { logger, sharedConfig } from '@finance/shared';
import { PlatformError } from '../../errors';

interface ExchangePublicTokenResponse {
    access_token: string;
    item_id: string;
    request_id: string;
}

export default class PlaidApi {
    private client: PlaidClient;

    private environment() {
        if (sharedConfig.env.isProductionEnv) {
            return PlaidEnvironments.production;
        }
        return PlaidEnvironments.sandbox;
    }

    constructor() {
        const configuration = new Configuration({
            basePath: this.environment(),
            baseOptions: {
                headers: {
                    'PLAID-CLIENT-ID': platformConfig.plaid.clientId,
                    'PLAID-SECRET': platformConfig.plaid.secret
                }
            }
        });
        this.client = new PlaidClient(configuration);
    }

    async createLinkToken({
        userMemberId,
        userEmail,
        plaidProducts,
        accessToken
    }: {
        userMemberId: string;
        userEmail: string;
        plaidProducts?: Products[];
        accessToken?: string;
    }): Promise<string> {
        const request: LinkTokenCreateRequest = {
            user: {
                client_user_id: userMemberId,
                email_address: userEmail
            },
            client_name: 'HoneyBook',
            products: plaidProducts,
            country_codes: [CountryCode.Us, CountryCode.Ca],
            language: 'en',
            webhook: platformConfig.plaid.webhookUrl,
            access_token: accessToken
        };

        try {
            const res = await this.client.linkTokenCreate(request);
            return res.data.link_token;
        } catch (error) {
            throw new PlatformError({
                message: '[PlaidAPI] Error creating link token',
                obj: {
                    userMemberId,
                    userEmail,
                    plaidProducts,
                    error
                }
            });
        }
    }

    async exchangePublicToken(public_token: string): Promise<ExchangePublicTokenResponse> {
        try {
            const res = await this.client.itemPublicTokenExchange({
                public_token
            });
            return res.data;
        } catch (error) {
            throw new PlatformError({
                message: '[PlaidAPI] Error exchanging public token',
                obj: {
                    error
                }
            });
        }
    }

    private getISOString24HoursAgo(): string {
        const now = new Date();
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
        return yesterday.toISOString();
    }

    async getBalance(access_token: string): Promise<AccountsGetResponse> {
        try {
            const res = await this.client.accountsBalanceGet({
                access_token,
                options: {
                    // Required by Plaid for Capital One institution. Ignored for other institutions.
                    min_last_updated_datetime: this.getISOString24HoursAgo()
                }
            });
            return res.data;
        } catch (error) {
            throw new PlatformError({
                message: '[PlaidAPI] Error getting balance',
                obj: {
                    error
                }
            });
        }
    }

    async getWebhookVerificationKey(key_id: string): Promise<JWKPublicKey> {
        try {
            const res = await this.client.webhookVerificationKeyGet({
                key_id
            });
            logger.debug({ res }, '[PlaidAPI] Webhook verification key response');
            return res.data.key;
        } catch (error) {
            throw new PlatformError({
                message: '[PlaidAPI] Error getting webhook verification key',
                obj: {
                    key_id,
                    error
                }
            });
        }
    }

    async syncTransactions({ access_token, cursor }: { access_token: string; cursor?: string }): Promise<TransactionsSyncResponse> {
        try {
            const res = await this.client.transactionsSync({
                access_token,
                cursor,
                count: 500
            });
            return res.data;
        } catch (error) {
            throw new PlatformError({
                message: '[PlaidAPI] Error syncing transactions',
                obj: {
                    error
                }
            });
        }
    }

    async getInstitutionLogo(institutionId: string): Promise<string | null> {
        try {
            const res = await this.client.institutionsGetById({
                institution_id: institutionId,
                country_codes: [CountryCode.Us, CountryCode.Ca],
                options: {
                    include_optional_metadata: true
                }
            });
            // todo: add a default logo if not found.
            return res?.data?.institution?.logo ?? '';
        } catch (error) {
            throw new PlatformError({
                message: '[PlaidAPI] Error getting institution logo',
                obj: {
                    institutionId,
                    error
                }
            });
        }
    }

    async deleteItem(access_token: string): Promise<void> {
        try {
            await this.client.itemRemove({
                access_token
            });
        } catch (error) {
            throw new PlatformError({
                message: '[PlaidAPI] Error deleting item',
                obj: {
                    error
                }
            });
        }
    }
}
