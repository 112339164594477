import { Customer, IndividualCustomer, FullName, Application, EntityType } from '@unit-finance/unit-node-sdk';
import { EntityTypeEnum } from '../../types';

export const getDisplayName = (customer: Customer) => {
    const fullName = getFullName(customer);
    return `${fullName.first}${fullName.last && ` ${fullName.last}`}`;
};

export const getFirstName = (customer: Customer) => {
    return isIndividualCustomer(customer) ? customer.attributes.fullName.first : customer.attributes.name;
};

export const getFullName = (customer: Customer): FullName => {
    if (isIndividualCustomer(customer)) {
        return {
            ...customer.attributes.fullName
        };
    }

    const name = customer.attributes.name;
    let lastSpace = name.lastIndexOf(' ');
    if (lastSpace < 0) {
        lastSpace = name.length;
    }

    return {
        first: name.slice(0, lastSpace),
        last: name.slice(lastSpace + 1)
    };
};

export const getEmail = (customer: Customer) => {
    return isIndividualCustomer(customer) ? customer.attributes.email : customer.attributes.contact.email;
};

export const getPhone = (customer: Customer) => {
    return isIndividualCustomer(customer) ? customer.attributes.phone : customer.attributes.contact.phone;
};

const isIndividualCustomer = (customer: Customer): customer is IndividualCustomer => {
    return customer.type === 'individualCustomer';
};

export const getEntityTypeFromApplication = (application: Application): EntityType | EntityTypeEnum => {
    if (application.type === 'businessApplication') {
        return application.attributes.entityType;
    }
    return EntityTypeEnum.SoleProprietor;
};
