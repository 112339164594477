import { FintechEntity } from './models';
import PlatformError from '../errors/platform-error';

export class PersistenceError<Entity extends FintechEntity = FintechEntity> extends PlatformError {
    private readonly _entities?: Entity[];

    constructor(message: string, entities?: Entity[]) {
        super({ message });
        this.name = 'PersistenceError';
        this._entities = entities;
    }

    public get entities(): Entity[] | undefined {
        return this._entities;
    }
}
