import { Db } from 'mongodb';
import { FintechTaxEntityDTO } from '../../models/tax-entity';
import { AbstractMongoDataService } from './abstract-mongo-data-service';
import { FintechTaxEntityDataService, WithId } from '../../../types/dal-types';
import { MongoCollectionsEnum } from '../../../types';
import { ObjectId } from 'bson';

export class TaxEntityMongoDataService
    extends AbstractMongoDataService<FintechTaxEntityDTO>
    implements FintechTaxEntityDataService<FintechTaxEntityDTO>
{
    constructor(db: Db) {
        super(db);
    }

    getCollectionName(): MongoCollectionsEnum {
        return MongoCollectionsEnum.TAX_ENTITIES;
    }

    async findTaxEntityByHbMemberId(hbMemberId: string): Promise<WithId<FintechTaxEntityDTO>[]> {
        return await this.collection.find({ hbMemberId: new ObjectId(hbMemberId) }).toArray();
    }

    async findTaxEntityByHbTaxEntityId(hbTaxEntityId: string): Promise<WithId<FintechTaxEntityDTO> | null> {
        return await this.collection.findOne({ hbTaxEntityId: new ObjectId(hbTaxEntityId) });
    }
}
