import { CustomerTokenPurposeEnum } from '../types';
import { StatusCodes } from 'http-status-codes';

export function getCustomerTokenKey(customerId: string, fingerprint: string, customerTokenPurpose: CustomerTokenPurposeEnum) {
    return `${customerId}-${fingerprint}-${customerTokenPurpose}`;
}

export function isBadRequest(status?: number | string): boolean {
    const statusCode = Number(status);
    return statusCode >= StatusCodes.BAD_REQUEST && statusCode < StatusCodes.INTERNAL_SERVER_ERROR;
}

export function isBlank(str: string | null | undefined) {
    return !str || str.trim().length === 0;
}
