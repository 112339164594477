import { DepositAccount } from '@unit-finance/unit-node-sdk';
import platformConfig from '../config/platformConfig';

export const getHBOAccountId = (account: DepositAccount) => {
    const { depositProduct } = account.attributes;
    const {
        unit: { legacyDepositProduct, newPayoutsOrgAccountId, payoutsOrgAccountId }
    } = platformConfig;

    // checking_ means a migrated account (legacy account are just "checking")
    return depositProduct == legacyDepositProduct ? payoutsOrgAccountId : newPayoutsOrgAccountId;
};
