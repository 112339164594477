import { Db } from 'mongodb';
import { AbstractMongoDataService } from './abstract-mongo-data-service';
import { ObjectId } from 'bson';
import { PlaidInstitutionAuth } from '../../models';
import { FintechPlaidInstitutionAuthDataService, WithId } from '../../../types/dal-types';
import { MongoCollectionsEnum, PlaidInstitutionAuthStatus } from '../../../types';
import { logger } from '@finance/shared';

export class PlaidInstitutionAuthMongoDataService
    extends AbstractMongoDataService<PlaidInstitutionAuth>
    implements FintechPlaidInstitutionAuthDataService<PlaidInstitutionAuth>
{
    constructor(db: Db) {
        super(db);
    }

    getCollectionName(): MongoCollectionsEnum {
        return MongoCollectionsEnum.PLAID_INSTITUTION_AUTHS;
    }

    async readAllValidInstitutionAuths(): Promise<WithId<PlaidInstitutionAuth>[]> {
        return await this.collection
            .find({ $or: [{ status: PlaidInstitutionAuthStatus.PendingData }, { status: PlaidInstitutionAuthStatus.Ok }] })
            .toArray();
    }

    async findPlaidInstitutionAuthByItemId(itemId: string): Promise<PlaidInstitutionAuth | null> {
        return await this.collection.findOne({
            itemId
        });
    }

    async findPlaidInstitutionAuthsByHbMemberId(hbMemberId: string): Promise<WithId<PlaidInstitutionAuth>[]> {
        return await this.collection.find({ hbMemberId: new ObjectId(hbMemberId) }).toArray();
    }

    async findPlaidInstitutionAuthByHbMemberIdAndInstitutionId(
        hbMemberId: string,
        institutionId: string
    ): Promise<WithId<PlaidInstitutionAuth> | null> {
        return await this.collection.findOne({
            hbMemberId: new ObjectId(hbMemberId),
            institutionId
        });
    }

    async delete(id: ObjectId) {
        logger.debug({ id }, 'Deleting PlaidInstitutionAuth');
        const res = await super.delete(id);
        logger.debug({ id }, 'Deleted PlaidInstitutionAuth');
        return res;
    }
}
