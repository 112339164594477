import { ObjectId } from 'bson';
import { isNil, omitBy } from 'lodash';
import { FintechEntity } from '../../models';
import { FintechDataService, WithId } from '../../../types/dal-types';
import { logger } from '@finance/shared/dist/utils/logger';

export abstract class AbstractInMemoryDataService<Entity extends FintechEntity = FintechEntity> implements FintechDataService<Entity> {
    readonly storage: Record<string, Entity>;

    protected constructor(storage: Record<string, Entity>) {
        this.storage = storage;
    }

    private toKey(id: ObjectId) {
        return id.toHexString();
    }

    async create(entity: Entity) {
        entity._id = new ObjectId();
        logger.debug({ entity }, 'InMemoryDB: create');
        this.storage[this.toKey(entity._id)] = entity;
        return <WithId<Entity>>entity;
    }

    async delete(id: ObjectId) {
        logger.debug({ id }, 'InMemoryDB: delete');
        const key = this.toKey(id);
        if (this.storage[key]) {
            delete this.storage[key];
            return true;
        }

        return false;
    }

    async deleteMany(ids: ObjectId[]) {
        logger.debug({ ids }, 'InMemoryDB: deleteMany');
        return (await Promise.all(ids.map(async id => ((await this.delete(id)) ? 1 : 0)))).reduce(
            (sum: number, isDeleted: number) => sum + isDeleted,
            0
        );
    }

    async read(id: ObjectId) {
        const result = <WithId<Entity>>this.storage[this.toKey(id)] ?? null;
        logger.debug({ id, result }, 'InMemoryDB: read');
        return result;
    }

    async readAll() {
        return <WithId<Entity>[]>Object.values(this.storage);
    }

    async update(entity: Entity) {
        logger.debug({ entity }, 'InMemoryDB: update');
        if (!entity._id || !this.storage[this.toKey(entity._id)]) {
            return await this.create(entity);
        }

        const updatedEntity = {
            ...this.storage[this.toKey(entity._id)],
            ...omitBy(entity, isNil)
        };
        this.storage[this.toKey(entity._id)] = updatedEntity;
        return <WithId<Entity>>updatedEntity;
    }

    async unset(id: ObjectId, field: string[]): Promise<boolean> {
        const key = this.toKey(id);
        if (this.storage[key]) {
            field.forEach(f => {
                delete this.storage[key][f];
            });
            return true;
        }

        return false;
    }
}
