import PlatformError from './platform-error';

export class PlatformApiError extends PlatformError {
    public readonly status?: number;
    public readonly baseURL?: string;
    public readonly url?: string;
    public readonly method?: string;
    public readonly isUnitError?: boolean;

    constructor({
        message,
        originalError,
        metadata,
        status,
        baseURL,
        url,
        method,
        isUnitError
    }: {
        message: string;
        originalError?: Error;
        metadata?: object;
        status?: number;
        baseURL?: string;
        url?: string;
        method?: string;
        isUnitError?: boolean;
    }) {
        super({
            message,
            originalError,
            obj: metadata
        });

        this.status = status;
        this.baseURL = baseURL;
        this.url = url;
        this.method = method;
        this.isUnitError = isUnitError;
    }
}
export default PlatformApiError;
