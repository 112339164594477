/* eslint-disable no-console */
import { Client } from '@notionhq/client';
import { PageObjectResponse, RichTextItemResponse } from '@notionhq/client/build/src/api-endpoints';
import { set } from 'lodash';

const L10N_DB_ID = '0794d023848b441cb4617534e0eceb93';

type TEntry = {
    id: string;
    type: string;
};

export type TKey = TEntry & {
    title: Array<RichTextItemResponse>;
};

export type TValue = TEntry & {
    rich_text: Array<RichTextItemResponse>;
};

export type TStatus = TEntry & {
    status: {
        id: string;
        name: string;
        color: string;
    };
};

export interface IDictionary<T> {
    [key: string]: T;
}

export interface IKeyData {
    key: string;
    review?: boolean;
    namespace: string;
    value: string;
}

export async function updateKey(localeKeyData: IKeyData, createIfMissing = false) {
    const notion = new Client({
        auth: process.env.NOTION_KEY
    });
    const result = await getPageByKey(localeKeyData.key, localeKeyData.namespace);
    if (result.results.length) {
        const pageId = result.results[0].id;
        await notion.pages.update({
            page_id: pageId,
            properties: {
                Value: {
                    rich_text: [
                        {
                            text: {
                                content: localeKeyData.value
                            }
                        }
                    ]
                }
            }
        });
    } else if (createIfMissing) {
        await notion.pages.create({
            parent: {
                database_id: L10N_DB_ID
            },
            properties: {
                Key: {
                    title: [
                        {
                            text: {
                                content: localeKeyData.key
                            }
                        }
                    ]
                },
                Value: {
                    rich_text: [
                        {
                            text: {
                                content: localeKeyData.value
                            }
                        }
                    ]
                },
                Namespace: {
                    select: {
                        name: localeKeyData.namespace
                    }
                }
            }
        });
    }
    return true;
}

export async function getLocaleDataByNamespace(namespace: string) {
    const notion = new Client({
        auth: process.env.NOTION_KEY
    });
    return await notion.databases.query({
        database_id: L10N_DB_ID,
        filter: {
            and: [
                {
                    property: 'Namespace',
                    select: {
                        equals: namespace
                    }
                },
                {
                    property: 'Status',
                    status: {
                        does_not_equal: 'TBD'
                    }
                }
            ]
        }
    });
}

export async function fetchNamespaceLocaleData(namespace: string) {
    const dict: IDictionary<string> = {};
    const results = (await getLocaleDataByNamespace(namespace)).results as PageObjectResponse[];
    results.forEach(entry => {
        const keyEntry = (entry.properties['Key'] as TKey).title.pop() as RichTextItemResponse;
        const valueEntry = (entry.properties['Value'] as TValue).rich_text.pop() as RichTextItemResponse;
        const isInReview = (entry.properties['Status'] as TStatus).status.name === 'In Review';
        set(dict, `${keyEntry.plain_text}${isInReview ? '_review' : ''}`, valueEntry.plain_text);
    });

    return dict;
}

export function sortLocaleData(localeData: IDictionary<string>) {
    return Object.keys(localeData)
        .sort()
        .reduce((acc: IDictionary<string>, key) => {
            acc[key] = localeData[key];
            return acc;
        }, {});
}

function getPageByKey(key: string, ns: string) {
    const notion = new Client({
        auth: process.env.NOTION_KEY
    });
    return notion.databases.query({
        database_id: L10N_DB_ID,
        filter: {
            and: [
                {
                    property: 'Namespace',
                    select: {
                        equals: ns
                    }
                },
                {
                    property: 'Key',
                    title: {
                        equals: key
                    }
                }
            ]
        }
    });
}
