import { Db, WithId } from 'mongodb';
import { AbstractMongoDataService } from './abstract-mongo-data-service';
import { FintechGoalsDataService } from '../../../types/dal-types';
import { MongoCollectionsEnum } from '../../../types';
import { GoalModel } from '../../models/goal';
import { ObjectId } from 'bson';

export class GoalMongoDataService extends AbstractMongoDataService<GoalModel> implements FintechGoalsDataService<GoalModel> {
    constructor(db: Db) {
        super(db);
    }

    getCollectionName(): MongoCollectionsEnum {
        return MongoCollectionsEnum.GOAL;
    }

    async findYearlyRevenueGoalByCompanyId({
        companyId,
        userId,
        startDate,
        endDate
    }: {
        companyId: string;
        userId: string;
        startDate: Date;
        endDate: Date;
    }): Promise<WithId<GoalModel> | null> {
        const goalByCompany = await this.collection.findOne({ companyId: new ObjectId(companyId), startDate, endDate });
        if (!goalByCompany) {
            const goalByUser = await this.collection.findOne({ userId: new ObjectId(userId), startDate, endDate });
            if (goalByUser) {
                // lazily migrate the goal to the company
                await this.collection.updateOne({ _id: goalByUser._id }, { $set: { companyId: new ObjectId(companyId) } });
                return goalByUser;
            }
        }
        return goalByCompany;
    }
}
