import { FintechGoalsDataService, WithId } from '../types/dal-types';
import { logger } from '@finance/shared';
import { GoalModel, GoalModelDTO } from '../dal/models/goal';
import { ObjectId } from 'bson';

export type UpdateGoalParams = Partial<Omit<GoalModelDTO, 'id'>> & { id: string };
export class GoalDataService {
    readonly dataService: FintechGoalsDataService<GoalModel>;

    constructor({ dataService }: { dataService: FintechGoalsDataService<GoalModel> }) {
        this.dataService = dataService;
    }

    async create(entity: GoalModelDTO): Promise<WithId<GoalModel>> {
        logger.info({ entity }, 'GoalDataService: create');

        const createdGoal = await this.dataService.create({
            ...entity,
            userId: new ObjectId(entity.userId),
            companyId: new ObjectId(entity.companyId)
        });
        return <WithId<GoalModel>>createdGoal;
    }

    async delete(id: string): Promise<boolean> {
        logger.info({ id }, 'GoalDataService: delete');
        return await this.dataService.delete(new ObjectId(id));
    }

    async update(entity: UpdateGoalParams): Promise<WithId<GoalModel>> {
        logger.info({ entity }, 'GoalDataService: update');
        const existingGoal = await this.dataService.read(new ObjectId(entity.id));
        if (!existingGoal) {
            throw new Error('Goal not found');
        }
        const updatedGoal = await this.dataService.update({
            ...existingGoal,
            ...entity,
            _id: new ObjectId(entity.id),
            userId: entity.userId ? new ObjectId(entity.userId) : existingGoal.userId,
            companyId: entity.companyId ? new ObjectId(entity.companyId) : existingGoal.companyId
        });
        return <WithId<GoalModel>>updatedGoal;
    }

    async findYearlyRevenueGoalByCompanyId({
        companyId,
        userId,
        startDate,
        endDate
    }: {
        companyId: string;
        userId: string;
        startDate: Date;
        endDate: Date;
    }): Promise<WithId<GoalModel> | null> {
        return await this.dataService.findYearlyRevenueGoalByCompanyId({ companyId, userId, startDate, endDate });
    }
}
