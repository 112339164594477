import { FintechTaxEntityDataService, WithId } from '../types/dal-types';
import { FintechTaxEntityDTO, FintechTaxEntity } from '../dal/models/tax-entity';
import { ObjectId } from 'bson';
import { logger } from '@finance/shared';
import { compact } from 'lodash';
import { PlatformError } from '../errors';
import { decrypt, encrypt } from '../utils/crypto-utils';

export class TaxEntityDataService {
    readonly dataService: FintechTaxEntityDataService<FintechTaxEntityDTO>;

    constructor({ dataService }: { dataService: FintechTaxEntityDataService<FintechTaxEntityDTO> }) {
        this.dataService = dataService;
    }

    static finTaxEntityToFinTaxEntityDto = (finTaxEntity: FintechTaxEntity): FintechTaxEntityDTO => {
        return {
            _id: finTaxEntity._id ? new ObjectId(finTaxEntity._id) : undefined,
            hbMemberId: finTaxEntity.hbMemberId ? new ObjectId(finTaxEntity.hbMemberId) : undefined,
            hbTaxEntityId: finTaxEntity.hbTaxEntityId ? new ObjectId(finTaxEntity.hbTaxEntityId) : undefined,
            tin: finTaxEntity.tin ? encrypt(finTaxEntity.tin, finTaxEntity.hbTaxEntityId) : undefined
        } as FintechTaxEntityDTO;
    };

    static finTaxEntityDtoToFinTaxEntity = (finTaxEntity: FintechTaxEntityDTO): FintechTaxEntity => {
        const hbTaxEntityId = finTaxEntity.hbTaxEntityId.toHexString();
        return {
            _id: finTaxEntity._id ? finTaxEntity._id.toHexString() : undefined,
            hbMemberId: finTaxEntity.hbMemberId.toHexString(),
            hbTaxEntityId,
            tin: finTaxEntity.tin ? decrypt(finTaxEntity.tin, hbTaxEntityId) : undefined
        };
    };

    async create(entity: FintechTaxEntity): Promise<WithId<FintechTaxEntity>> {
        logger.info({ entity }, 'TaxEntityDataService: create');

        const mandatoryFields = [entity.hbMemberId, entity.hbTaxEntityId, entity.tin];
        if (compact(mandatoryFields).length !== mandatoryFields.length) {
            throw new PlatformError({
                message: 'TaxEntity hbMemberId, hbTaxEntityId, and tin are mandatory fields',
                obj: { entity }
            });
        }

        const existingEntity = await this.findTaxEntityByHbTaxEntityId(entity.hbTaxEntityId);
        if (existingEntity) {
            throw new PlatformError({
                message: `TaxEntity with hbTaxEntityId ${entity.hbTaxEntityId} already exists`,
                obj: { entity, existingEntity }
            });
        }

        const finTaxEntityDto = TaxEntityDataService.finTaxEntityToFinTaxEntityDto(entity);
        const createdFinTaxEntityDto = await this.dataService.create(finTaxEntityDto);
        entity._id = createdFinTaxEntityDto?._id.toHexString();
        return <WithId<FintechTaxEntity>>entity;
    }

    async read(id: string): Promise<WithId<FintechTaxEntity> | null> {
        const finTaxEntityDto = await this.dataService.read(new ObjectId(id));
        return finTaxEntityDto ? <WithId<FintechTaxEntity>>TaxEntityDataService.finTaxEntityDtoToFinTaxEntity(finTaxEntityDto) : null;
    }

    async update(entity: Partial<FintechTaxEntity>): Promise<WithId<FintechTaxEntity>> {
        let finTaxEntityDto = TaxEntityDataService.finTaxEntityToFinTaxEntityDto(entity as FintechTaxEntity);
        if (entity._id) {
            const existingEntity = await this.dataService.read(new ObjectId(entity._id));
            if (!existingEntity) {
                throw new PlatformError({
                    message: `TaxEntity with id ${entity._id} does not exist`,
                    obj: { entity }
                });
            }

            finTaxEntityDto = (await this.dataService.update({
                _id: finTaxEntityDto._id,
                hbMemberId: finTaxEntityDto.hbMemberId ? finTaxEntityDto.hbMemberId : existingEntity.hbMemberId,
                hbTaxEntityId: finTaxEntityDto.hbTaxEntityId ? finTaxEntityDto.hbTaxEntityId : existingEntity.hbTaxEntityId,
                tin: finTaxEntityDto.tin ? finTaxEntityDto.tin : existingEntity.tin
            })) as WithId<FintechTaxEntityDTO>;
            entity = TaxEntityDataService.finTaxEntityDtoToFinTaxEntity(finTaxEntityDto);
        } else {
            entity = await this.create(entity as FintechTaxEntity);
        }

        return <WithId<FintechTaxEntity>>entity;
    }

    async delete(id: string): Promise<boolean> {
        return await this.dataService.delete(new ObjectId(id));
    }

    async findTaxEntityByHbMemberId(hbMemberId: string): Promise<WithId<FintechTaxEntity>[]> {
        const finTaxEntityDto = await this.dataService.findTaxEntityByHbMemberId(hbMemberId);
        return finTaxEntityDto.map(
            <(ent: FintechTaxEntityDTO) => WithId<FintechTaxEntity>>TaxEntityDataService.finTaxEntityDtoToFinTaxEntity
        );
    }

    async findTaxEntityByHbTaxEntityId(hbTaxEntityId: string): Promise<WithId<FintechTaxEntity> | null> {
        const finTaxEntityDto = await this.dataService.findTaxEntityByHbTaxEntityId(hbTaxEntityId);
        return finTaxEntityDto ? <WithId<FintechTaxEntity>>TaxEntityDataService.finTaxEntityDtoToFinTaxEntity(finTaxEntityDto) : null;
    }
}
