import {
    Application,
    ApplicationDocument,
    ApplicationListParams,
    ApplicationType,
    CreateApplicationRequest,
    UnitError
} from '@unit-finance/unit-node-sdk';
import UnitIterableApi, { IterableApiName } from './unit.iterable.api';
import platformConfig from '../../config/platformConfig';
import { ApiContext, ApplicationStatusEnum, ApplicationTags, NullablePartial } from '../../types';
import { logger } from '@finance/shared';
import { StatusCodes } from 'http-status-codes';
import { AxiosFactoryProps } from '@finance/shared/dist/utils/axios-factory';

const requiredTags = ['memberId', 'hbAccountId'];

const validate = (applicationData: CreateApplicationRequest) => {
    const tags = applicationData.attributes.tags;

    const missingTags = requiredTags.filter(requiredTag => !tags?.hasOwnProperty(requiredTag));

    if (missingTags.length) {
        logger.error({ missingTags }, 'An application tag is missing');
        return false;
    }

    return true;
};

export type ApplicationFilterOptions = {
    shouldFilterCancelled: boolean;
};

export default class ApplicationApi extends UnitIterableApi<Application, ApplicationListParams> {
    constructor(context?: ApiContext, axiosFactoryProps: Omit<AxiosFactoryProps, 'config'> = {}) {
        super(context, axiosFactoryProps);
    }

    async newApplication(applicationData: CreateApplicationRequest) {
        if (validate(applicationData)) {
            const tags = applicationData.attributes.tags as ApplicationTags;
            applicationData.attributes.idempotencyKey = `create-application-${tags.memberId}`;
            logger.debug(
                {
                    memberId: tags.memberId,
                    hbAccountId: tags.hbAccountId,
                    type: applicationData.type,
                    idempotencyKey: applicationData.attributes.idempotencyKey
                },
                'Creating application'
            );

            // Bypass access control check because there is no customer context yet
            return await this.withoutAccessControl()
                .applications.create(applicationData)
                .catch(error => {
                    logger.error({ error }, 'Error creating application');
                    throw this.handleUnitError({
                        error: error as UnitError,
                        hbAccountId: tags.hbAccountId,
                        memberId: tags.memberId
                    });
                });
        }

        logger.error({ applicationData }, 'validation failed');
        return null;
    }

    async get(applicationId: string) {
        return this.withoutAccessControl()
            .applications.get(applicationId)
            .then(application => application.data)
            .catch(err => {
                this.handleUnitError({ error: err as UnitError });
                return null;
            });
    }

    async getApplicationByMemberId(memberId: string, filterOptions?: ApplicationFilterOptions): Promise<Application> {
        const params: ApplicationListParams = {};
        params.tags = { memberId };

        if (filterOptions?.shouldFilterCancelled) {
            params.status = Object.values(ApplicationStatusEnum).filter(value => value != ApplicationStatusEnum.Canceled);
        }

        const applications = await this.list(params);
        return applications[0];
    }

    // Note: Overrides base implementation to bypass the access control. Figure out a better way to do this.
    async list(params?: ApplicationListParams): Promise<Application[]> {
        return this.withoutAccessControl()
            .applications.list(params)
            .then(res => res.data)
            .catch(err => {
                throw this.handleUnitError({ error: err as UnitError });
            });
    }

    /**
     * See [List Documents](https://docs.unit.co/application-documents#list-documents)
     * @param applicationId ID of the application to get documents of
     */
    async listDocuments(applicationId: string): Promise<ApplicationDocument[]> {
        return this.withoutAccessControl()
            .applications.listDocuments(applicationId)
            .then(res => res.data)
            .catch(err => {
                throw this.handleUnitError({ error: err as UnitError });
            });
    }

    async getUploadToken() {
        const {
            unit: { userId: unitUserId, applicationTokenDuration: tokenDuration }
        } = platformConfig;
        let errorData: object = {};
        const expiration = new Date(Date.now() + tokenDuration).toISOString();
        logger.info({ unitUserId, expiration }, 'Get upload token for upload documents');
        try {
            const res = await this.axios.post(`/users/${unitUserId}/api-tokens`, {
                data: {
                    type: 'apiToken',
                    attributes: {
                        description: 'Application documents token',
                        scope: 'applications-write',
                        expiration
                    }
                }
            });

            if (res.status === StatusCodes.CREATED) {
                const {
                    data: {
                        attributes: { token }
                    }
                } = res.data;

                return `Bearer ${token}`;
            } else {
                errorData = { response: res };
            }
        } catch (error) {
            errorData = { error };
        }

        logger.error(
            {
                unitUserId,
                expiration,
                ...errorData
            },
            'Could not generate bearer token for upload documents'
        );
        return '';
    }

    async update({
        applicationId,
        applicationType,
        tags
    }: {
        applicationId: string;
        applicationType: ApplicationType;
        tags: NullablePartial<ApplicationTags>;
    }): Promise<Application> {
        const api = await this.withAccessControl({ applicationId });
        try {
            const application = await api.applications.update({
                applicationId,
                data: {
                    type: applicationType,
                    attributes: {
                        tags
                    }
                }
            });

            return application.data;
        } catch (error) {
            throw this.handleUnitError({ error: error as UnitError });
        }
    }

    protected get apiName(): IterableApiName {
        return 'applications';
    }
}
