export const getIdempotencyTimeframe = () => {
    return Math.floor(new Date().getTime() / 5000);
};

export const now = () => {
    return new Date();
};

export const nowISO = () => {
    return now().toISOString();
};