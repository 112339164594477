import logger from '@finance/shared/dist/utils/logger';
import { isBadRequest } from '../utils';

export class PlatformError extends Error {
    public readonly originalError?: Error;
    public readonly metadata?: object;

    constructor({ message, originalError, obj }: { message: string; originalError?: Error; obj?: object }) {
        super(message);
        this.name = this.constructor.name;
        this.originalError = originalError;
        this.metadata = obj;

        const logObj = {
            ...obj,
            errorType: this.name,
            error: originalError
        };

        // To keep DataDog clean from user errors
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (isBadRequest(obj?.status)) {
            logger.debug(logObj, message);
        } else {
            logger.error(logObj, message);
        }
    }

    public static isError(error: unknown): error is Error {
        return typeof error === 'object' && error !== null && 'message' in error;
    }

    public static isPlatformError(error: unknown): error is PlatformError {
        return error instanceof PlatformError;
    }

    public static toPlatformError(error: unknown): PlatformError {
        if (this.isPlatformError(error)) {
            return error;
        }

        if (this.isError(error)) {
            return new PlatformError({
                message: error.message,
                originalError: error
            });
        }

        return new PlatformError({ message: JSON.stringify(error) });
    }

    public static create(message: string, error?: unknown): PlatformError {
        if (this.isPlatformError(error)) {
            error.message = `${message}. Caused by: ${error.message}`;
            return error;
        }

        if (this.isError(error)) {
            return new PlatformError({ message, originalError: error });
        }

        return new PlatformError({ message, obj: { error } });
    }
}
export default PlatformError;
