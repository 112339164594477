import { AbstractInMemoryDataService } from './abstract-in-memory-data-service';
import { FintechGoalsDataService, WithId } from '../../../types/dal-types';
import logger from '@finance/shared/dist/utils/logger';
import { GoalModel } from '../../models/goal';
import _ from 'lodash';

export class GoalInMemoryDataService extends AbstractInMemoryDataService<GoalModel> implements FintechGoalsDataService<GoalModel> {
    public constructor(storage: Record<string, GoalModel>) {
        super(storage);
        logger.debug({ storage }, 'InMemoryDB: GoalInMemoryDataService.new');
    }

    async findYearlyRevenueGoalByCompanyId({
        companyId,
        startDate,
        endDate
    }: {
        companyId: string;
        startDate: Date;
        endDate: Date;
    }): Promise<WithId<GoalModel> | null> {
        const goal = _.find(
            this.storage,
            goal =>
                goal.companyId.toHexString() === companyId &&
                goal.startDate.getTime() === startDate.getTime() &&
                goal.endDate.getTime() === endDate.getTime()
        );
        return goal ? <WithId<GoalModel>>goal : null;
    }
}
