import { Db } from 'mongodb';
import { FintechAccountDTO } from '../../models/account';
import { AbstractMongoDataService } from './abstract-mongo-data-service';
import { FintechAccountDataService, WithId } from '../../../types/dal-types';
import { MongoCollectionsEnum } from '../../../types';
import { ObjectId } from 'bson';

export class AccountMongoDataService
    extends AbstractMongoDataService<FintechAccountDTO>
    implements FintechAccountDataService<FintechAccountDTO>
{
    constructor(db: Db) {
        super(db);
    }

    getCollectionName(): MongoCollectionsEnum {
        return MongoCollectionsEnum.ACCOUNTS;
    }

    async findAccountByUnitAccountId(unitAccountId: string): Promise<WithId<FintechAccountDTO> | null> {
        return await this.collection.findOne({ unitAccountId });
    }

    async findAccounts(customerId: string): Promise<WithId<FintechAccountDTO>[]> {
        return await this.collection.find({ customerId: new ObjectId(customerId) }).toArray();
    }

    async findAccountsByPurpose(customerId: string, purpose: string): Promise<WithId<FintechAccountDTO>[]> {
        return await this.collection.find({ customerId: new ObjectId(customerId), 'tags.purpose': purpose }).toArray();
    }
}
