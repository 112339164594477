import { Account, AccountListParams, CreditAccountCloseReason, Customer, DepositAccount, UnitError } from '@unit-finance/unit-node-sdk';
import UnitIterableApi, { IterableApiName } from './unit.iterable.api';
import { AccountPurposeEnum, AccountTags, ApiContext, NullablePartial } from '../../types';
import { logger } from '@finance/shared';
import { getIdempotencyTimeframe } from '../../utils';
import { getDisplayName } from './customer-utils';
import { AxiosFactoryProps } from '@finance/shared/dist/utils/axios-factory';
import platformConfig from '../../config/platformConfig';

const isDepositAccount = (account: Account): account is DepositAccount => {
    return (account as DepositAccount).type === 'depositAccount';
};

/**
 * For tax withholding, we let customers decide whether they want to use this feature or not. When they do,
 * they can set it to 'smart' or a number between 0 and 1. If they set it to 'smart', we'll use a smart tax rate.
 */
export const SMART_TAX_WITHHOLDING = 'smart';

export type TBaseAccountSetup = {
    primary: DepositAccount;
    tax?: DepositAccount;
};

export interface GetAccountsOpts {
    excludeClosedAccounts: boolean;
}

export function isPrimaryAccount(account: DepositAccount) {
    return (account.attributes.tags as AccountTags)?.purpose === AccountPurposeEnum.Primary;
}

export function isTaxAccount(account: DepositAccount) {
    return (account.attributes.tags as AccountTags)?.purpose === AccountPurposeEnum.Tax;
}

export default class AccountApi extends UnitIterableApi<Account, AccountListParams> {
    constructor(context?: ApiContext, axiosFactoryProps: Omit<AxiosFactoryProps, 'config'> = {}) {
        super(context, axiosFactoryProps);
    }

    static findAccountByPurpose(accounts: DepositAccount[], purpose: AccountPurposeEnum) {
        return accounts.find(accountData => (accountData.attributes.tags as AccountTags).purpose === purpose);
    }
    async getPrimaryAccount(customerId: string): Promise<Account> {
        try {
            const api = await this.withAccessControl({ customerId });
            const { data: accounts } = await api.accounts.list({
                customerId,
                sort: '-createdAt',
                tags: { purpose: AccountPurposeEnum.Primary }
            });
            // sort accounts by createdAt to get the latest primary account
            const sortedAccounts = accounts.sort(
                (a, b) => new Date(b.attributes.createdAt).getTime() - new Date(a.attributes.createdAt).getTime()
            );
            return sortedAccounts[0];
        } catch (err) {
            throw this.handleUnitError({ error: err as UnitError });
        }
    }

    async getAccountsByPurpose(customerId: string, purpose: AccountPurposeEnum): Promise<Account[]> {
        try {
            const api = await this.withAccessControl({ customerId });
            const { data: accounts } = await api.accounts.list({
                customerId,
                tags: { purpose }
            });
            return accounts;
        } catch (err) {
            throw this.handleUnitError({ error: err as UnitError });
        }
    }

    async getAccountsByCustomerId(
        customerId: string,
        getAccountsOpts: GetAccountsOpts = { excludeClosedAccounts: true }
    ): Promise<{
        depositAccounts: DepositAccount[];
        allMemberAccounts: Account[];
    }> {
        try {
            const api = await this.withAccessControl({ customerId });
            const accounts = await api.accounts.list({ customerId });
            let res = accounts.data.filter(account => isDepositAccount(account)) as DepositAccount[];
            if (getAccountsOpts.excludeClosedAccounts) {
                res = res.filter(accountData => accountData.attributes.status !== 'Closed');
            }

            return {
                depositAccounts: res,
                allMemberAccounts: accounts.data
            };
        } catch (err) {
            throw this.handleUnitError({ error: err as UnitError });
        }
    }

    async getAccountData(customerId: string) {
        const { depositAccounts, allMemberAccounts } = await this.getAccountsByCustomerId(customerId);

        if (!depositAccounts.length) {
            return null;
        }

        const primaryAccount = AccountApi.findAccountByPurpose(depositAccounts, AccountPurposeEnum.Primary);

        const taxAccount = AccountApi.findAccountByPurpose(depositAccounts, AccountPurposeEnum.Tax);

        this.accountsMonitoring(customerId, allMemberAccounts, primaryAccount, taxAccount);

        return {
            primary:
                primaryAccount ??
                (await this.setAsPrimaryAccount({
                    accountId: depositAccounts[0].id
                })),
            tax: taxAccount ?? null
        };
    }

    private accountsMonitoring(
        customerId: string,
        allMemberAccounts: Account[],
        primaryAccount?: DepositAccount,
        taxAccount?: DepositAccount
    ) {
        if (!(primaryAccount && taxAccount)) {
            logger.warn(
                {
                    customerId,
                    primary: primaryAccount,
                    tax: taxAccount,
                    allMemberAccounts: allMemberAccounts.map(account => {
                        const { id, type, attributes } = account;
                        return { id, type, attributes };
                    })
                },
                'Primary or tax account not found'
            );
        }
    }

    async getAccountDataByPurpose(customerId: string, purpose: AccountPurposeEnum): Promise<DepositAccount | undefined> {
        try {
            const api = await this.withAccessControl({ customerId });
            const accounts = await api.accounts.list({
                customerId,
                tags: { purpose }
            });
            return accounts.data.find(account => isDepositAccount(account)) as DepositAccount;
        } catch (err) {
            throw this.handleUnitError({ error: err as UnitError });
        }
    }

    async getAccountById(accountId: string) {
        const api = await this.withAccessControl({ accountId });
        return api.accounts
            .get(accountId)
            .then(account => account.data)
            .catch(err => {
                throw this.handleUnitError({ error: err as UnitError });
            });
    }

    async setAsPrimaryAccount({ accountId }: { accountId: string }) {
        return await this.updateAccount({
            accountId,
            tags: {
                purpose: AccountPurposeEnum.Primary
            }
        });
    }

    async updateAccount({ accountId, tags }: { accountId: string; tags: NullablePartial<AccountTags> }) {
        this.validateTaxWithholdingTag(tags);
        const api = await this.withAccessControl({ accountId });
        return api.accounts
            .update({
                accountId,
                data: {
                    type: 'depositAccount',
                    attributes: {
                        tags
                    }
                }
            })
            .then(updateAccount => updateAccount.data as DepositAccount)
            .catch(err => {
                throw this.handleUnitError({ error: err as UnitError });
            });
    }

    private validateTaxWithholdingTag(tags: NullablePartial<AccountTags>) {
        const hasFixedTaxWithholdingRate = (accountTags: NullablePartial<AccountTags>) =>
            accountTags?.taxWithholdingPercentage && tags.taxWithholdingPercentage !== SMART_TAX_WITHHOLDING;

        if (hasFixedTaxWithholdingRate(tags)) {
            const taxWithholdingPercentage = parseFloat(<string>tags?.taxWithholdingPercentage);
            if (isNaN(taxWithholdingPercentage) || taxWithholdingPercentage < 0 || taxWithholdingPercentage > 1) {
                logger.error(`Ignoring invalid tax withholding percentage value: ${tags.taxWithholdingPercentage}`);
                throw new Error(`taxWithholdingPercentage must be between 0 and 1, or "smart". Was: ${tags.taxWithholdingPercentage}`);
            }
        }
    }

    async createAccount({ customer, tags = { purpose: AccountPurposeEnum.Primary } }: { customer: Customer; tags?: AccountTags }) {
        try {
            const {
                unit: { depositProduct }
            } = platformConfig;

            // todo: If the customer already has a primary account, we want to use the same deposit product for the new account (should be removed after the migration)
            let primaryDepositProduct = depositProduct;
            try {
                const primaryAccount = (await this.getPrimaryAccount(customer.id)) as DepositAccount;
                primaryDepositProduct = primaryAccount?.attributes.depositProduct ?? depositProduct;
            } catch (error) {
                logger.warn({ error }, 'Error getting primary account');
            }

            const api = await this.withAccessControl({ customerId: customer.id });
            const idempotencyKeyAppendix = tags.bucketName ?? '';
            const idempotencyKeySuffix =
                tags.purpose == AccountPurposeEnum.Savings ? `-${idempotencyKeyAppendix}-${getIdempotencyTimeframe()}` : '';
            const qboTags =
                tags.purpose === AccountPurposeEnum.Primary
                    ? { officialName: `${getDisplayName(customer)} - Checking`, hide: 'null' }
                    : { hide: 'true', officialName: 'null' };

            const { data: account } = await api.accounts.create({
                type: 'depositAccount',
                attributes: {
                    depositProduct: primaryDepositProduct,
                    tags: { ...tags, ...qboTags },
                    idempotencyKey: `${customer.id}-create-${tags.purpose}-account${idempotencyKeySuffix}`
                },
                relationships: {
                    customer: {
                        data: {
                            type: customer.type,
                            id: customer.id
                        }
                    }
                }
            });

            logger.info(
                {
                    account,
                    customer,
                    tags
                },
                'Created account for customer'
            );

            return account as DepositAccount;
        } catch (err) {
            throw this.handleUnitError({ error: err as UnitError });
        }
    }

    async closeAccount({ accountId, reason = 'ByCustomer' }: { accountId: string; reason?: CreditAccountCloseReason }) {
        const api = await this.withAccessControl({ accountId });
        return api.accounts
            .closeAccount({
                accountId,
                data: {
                    type: 'depositAccountClose',
                    attributes: {
                        reason
                    }
                }
            })
            .then(resp => resp.data)
            .catch(err => {
                throw this.handleUnitError({ error: err as UnitError });
            });
    }

    async reopenAccount(accountId: string) {
        const api = await this.withAccessControl({ accountId });
        return api.accounts
            .reopenAccount(accountId)
            .then(resp => resp.data)
            .catch(err => {
                throw this.handleUnitError({ error: err as UnitError });
            });
    }

    async getDepositAccount({ accountId }: { accountId: string }) {
        const api = await this.withAccessControl({ accountId });
        return api.accounts
            .get(accountId)
            .then(depositAccount => depositAccount.data as DepositAccount)
            .catch(err => {
                throw this.handleUnitError({ error: err as UnitError });
            });
    }

    async setupAccounts({ customer }: { customer: Customer }): Promise<TBaseAccountSetup> {
        logger.info({ customer }, 'setting up accounts for customer');
        return {
            primary: await this.createAccount({ customer }),
            tax: await this.createAccount({ customer, tags: { purpose: AccountPurposeEnum.Tax } })
        };
    }

    async getAccountLimits(accountId: string) {
        const api = await this.withAccessControl({ accountId });
        return api.accounts
            .limits(accountId)
            .then(accountLimits => accountLimits.data)
            .catch(err => {
                throw this.handleUnitError({ error: err as UnitError });
            });
    }

    async onCustomerCreated({ customer }: { customer: Customer }) {
        const tags = {
            purpose: AccountPurposeEnum.Primary
        };
        const api = await this.withAccessControl({ customerId: customer.id });
        const { data: existingAccounts } = await api.accounts.list({
            customerId: customer.id,
            tags
        });
        if (existingAccounts.length > 0) {
            logger.debug(
                { existingAccounts, purpose: tags.purpose },
                'An account with the same purpose already exists in unit. Skipping account create.'
            );
        } else {
            await this.createAccount({
                customer,
                tags
            });
        }
    }

    async allOpenAccounts() {
        return await this.withoutAccessControl().accounts.list({
            status: ['Open']
        });
    }

    protected get apiName(): IterableApiName {
        return 'accounts';
    }
}
