import { StatusCodes } from 'http-status-codes';
import PlatformError from './platform-error';

export class Forbidden extends PlatformError {
    constructor(obj?: object) {
        super({
            message: 'Access Denied',
            obj: {
                ...obj,
                status: StatusCodes.FORBIDDEN
            }
        });
    }
}
export default Forbidden;
