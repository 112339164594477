import { FintechAccountDataService, WithId } from '../types/dal-types';
import { FintechAccountDTO, FintechAccount } from '../dal/models/account';
import { FintechCustomer } from '../dal/models/customer';
import { ObjectId } from 'bson';
import AccountApi from '../api/unit/account.api';
import { Account, AccountLimits, Customer, DepositAccount, UnitError } from '@unit-finance/unit-node-sdk';
import { getDataStore } from '../dal/data-store';
import { AccountTags, ApiContext, NullablePartial } from '../types';
import { logger } from '@finance/shared';
import { isEqual } from 'lodash';
import { PlatformError } from '../errors';

export class AccountDataService {
    readonly dataService: FintechAccountDataService<FintechAccountDTO>;
    readonly unitAccountApi: AccountApi;

    constructor({
        dataService,
        unitAccountApi
    }: {
        dataService: FintechAccountDataService<FintechAccountDTO>;
        unitAccountApi?: AccountApi;
    }) {
        this.dataService = dataService;
        this.unitAccountApi = unitAccountApi ?? new AccountApi(undefined, { cacheAxios: false });
    }

    static finAccountToFinAccountDto = (finAccount: FintechAccount): FintechAccountDTO => {
        return {
            _id: finAccount._id ? new ObjectId(finAccount._id) : undefined,
            tags: finAccount.tags,
            customerId: new ObjectId(finAccount.customerId),
            unitAccountId: finAccount.unitAccount?.id
        };
    };

    static finAccountDtoToFinAccount = async ({
        finAccount,
        unitAccountApi
    }: {
        finAccount: FintechAccountDTO;
        unitAccountApi: AccountApi;
    }): Promise<FintechAccount> => {
        return {
            _id: finAccount._id ? finAccount._id.toHexString() : undefined,
            tags: finAccount.tags,
            customerId: finAccount.customerId.toHexString(),
            unitAccount: finAccount.unitAccountId
                ? ((await unitAccountApi.getAccountById(finAccount.unitAccountId)) as DepositAccount)
                : undefined as unknown as DepositAccount
        };
    };

    set context(context: ApiContext) {
        this.unitAccountApi.setContext(context);
    }

    async create(entity: Omit<FintechAccount, 'unitAccount'> & {unitAccount?: DepositAccount}): Promise<WithId<FintechAccount>> {
        logger.info({ entity }, 'AccountDataService: create');

        if (!entity.unitAccount) {
            const finCustomer = (await getDataStore().customerDataService.read(entity.customerId)) as WithId<FintechCustomer>;

            // For backward compatibility, if there is a unit account already, fetch it here. Otherwise, create a new one.
            try {
                const unitAccount = await this.unitAccountApi.getAccountDataByPurpose(
                    finCustomer.unitCustomer?.id as string,
                    entity.tags.purpose
                );

                // In case of savings account, we need to make sure we have the same tags. Otherwise, it is a new account.
                if (unitAccount && isEqual(unitAccount.attributes.tags, entity.tags)) {
                    entity.unitAccount = unitAccount;
                }
            } catch (ignore) {
                // Do nothing. Already logged by account api.
            }
            if (!entity.unitAccount) {
                entity.unitAccount = await this.unitAccountApi.createAccount({
                    customer: finCustomer.unitCustomer as Customer,
                    tags: entity.tags
                });
            }
        }

        // Avoid duplicate entities for the same Unit account
        const existingAccount = await this.dataService.findAccountByUnitAccountId(entity.unitAccount?.id as string);
        if (existingAccount) {
            return <WithId<FintechAccount>>await AccountDataService.finAccountDtoToFinAccount({
                finAccount: existingAccount,
                unitAccountApi: this.unitAccountApi
            });
        }

        const finAccountDto = AccountDataService.finAccountToFinAccountDto(entity as FintechAccount);
        const createdFinAccountDto = await this.dataService.create(finAccountDto);
        entity._id = createdFinAccountDto?._id.toHexString();
        return <WithId<FintechAccount>>entity;
    }

    async read(id: string): Promise<WithId<FintechAccount> | null> {
        const finAccountDto = await this.dataService.read(new ObjectId(id));
        return finAccountDto ? <WithId<FintechAccount>>await AccountDataService.finAccountDtoToFinAccount({
                  finAccount: finAccountDto,
                  unitAccountApi: this.unitAccountApi
              }) : null;
    }

    async update(
        entity: Omit<FintechAccount, 'tags'> & {
            tags: NullablePartial<AccountTags>;
        }
    ): Promise<WithId<FintechAccount>> {
        let finAccountDto: FintechAccountDTO;
        if (entity._id) {
            const existingEntity = await this.dataService.read(new ObjectId(entity._id));
            finAccountDto = (await this.dataService.update({
                ...existingEntity,
                customerId: new ObjectId(entity.customerId),
                tags: {
                    ...existingEntity?.tags,
                    ...entity.tags
                } as AccountTags
            })) as WithId<FintechAccountDTO>;
        } else {
            if (!entity.tags.purpose) {
                throw new PlatformError({
                    message: 'Account purpose is required',
                    obj: entity
                });
            }
            entity = (await this.create(entity as FintechAccount)) as WithId<FintechAccount>;
            finAccountDto = AccountDataService.finAccountToFinAccountDto(entity as FintechAccount);
        }

        // Keep unit tags synced with fintech tags
        if (!isEqual(finAccountDto?.tags, entity.unitAccount?.attributes?.tags)) {
            entity.unitAccount = await this.unitAccountApi.updateAccount({
                accountId: entity.unitAccount?.id as string,
                tags: entity.tags
            });

            // Now there might be a difference because of deleted tags. (sending null to Unit)
            // So catch this and update DB accordingly.
            if (!isEqual(finAccountDto?.tags, entity.unitAccount?.attributes?.tags)) {
                finAccountDto = (await this.dataService.update({
                    ...finAccountDto,
                    tags: entity.unitAccount?.attributes?.tags as AccountTags
                })) as WithId<FintechAccountDTO>;
            }
        }

        // Make sure all tags present in the entity
        entity.tags = finAccountDto.tags;

        return <WithId<FintechAccount>>entity;
    }

    async delete(id: string): Promise<boolean> {
        const account = await this.read(id);

        if (!account) {
            return false;
        }

        try {
            await this.unitAccountApi.closeAccount({accountId: account.unitAccount?.id as string});
        } catch (error) {
            // If account is already closed just swallow the error.
            const unitError = error as UnitError;
            if (!unitError.isUnitError || !unitError.message.includes('closed')) {
                throw error;
            }
        }

        return await this.dataService.delete(new ObjectId(id));
    }

    async findAccounts(customerId: string): Promise<WithId<FintechAccount>[]> {
        const finAccountsDto = await this.dataService.findAccounts(customerId);
        let accounts = await this.resolveMany(finAccountsDto);
        if (!accounts.length) {
            accounts = await this.fillInMissingAccountsFromUnit(customerId);
        }
        return accounts;
    }

    async findAccountsByPurpose(customerId: string, purpose: string): Promise<WithId<FintechAccount>[]> {
        const finAccountsDto = await this.dataService.findAccountsByPurpose(customerId, purpose);
        let accounts = await this.resolveMany(finAccountsDto);
        if (!accounts.length) {
            accounts = (await this.fillInMissingAccountsFromUnit(customerId)).filter(account => account.tags.purpose === purpose);
        }
        return accounts;
    }

    async findAccountByUnitAccountId(unitAccountId: string): Promise<WithId<FintechAccount> | null> {
        const finAccountDto = await this.dataService.findAccountByUnitAccountId(unitAccountId);
        if (finAccountDto) {
            return <WithId<FintechAccount>>await AccountDataService.finAccountDtoToFinAccount({
                finAccount: finAccountDto,
                unitAccountApi: this.unitAccountApi
            });
        }

        logger.info(
            { unitAccountId },
            'AccountDataService: findAccountByUnitAccountId: account not found in DB, trying to fetch from Unit'
        );
        try {
            const account = (await this.unitAccountApi.getAccountById(unitAccountId)) as DepositAccount;
            const unitCustomerId = account?.relationships?.customer?.data?.id;
            if (unitCustomerId) {
                const customer = await getDataStore().customerDataService.findCustomerByUnitCustomerId(unitCustomerId);
                if (customer) {
                    const accounts = await this.fillInMissingAccountsFromUnit(customer._id);
                    const finAccount = accounts.find(account => account.unitAccount?.id === unitAccountId);
                    return finAccount ?? null;
                }
            }
        } catch (error) {
            // Just warn because account.api already logs an error
            logger.warn(
                {
                    unitAccountId,
                    error
                },
                'AccountDataService: findAccountByUnitAccountId: failed to fetch from Unit'
            );
        }
        return null;
    }

    async getAccountLimits(unitAccountId: string): Promise<AccountLimits> {
        return await this.unitAccountApi.getAccountLimits(unitAccountId);
    }

    private async resolveMany(finAccountsDto: FintechAccountDTO[]): Promise<WithId<FintechAccount>[]> {
        if (finAccountsDto?.length) {
            const finAccounts = await Promise.all(
                finAccountsDto.map(finAccount => AccountDataService.finAccountDtoToFinAccount({ finAccount, ...this }))
            );
            return <WithId<FintechAccount>[]>finAccounts;
        }
        return [];
    }

    private async fillInMissingAccountsFromUnit(customerId: string) {
        logger.info({ customerId }, 'AccountDataService: fillInMissingAccountsFromUnit');
        let accounts: WithId<FintechAccount>[] = [];
        const customer = await getDataStore().customerDataService.read(customerId);
        if (customer?.unitCustomer?.id) {
            let allMemberAccounts: Account[];
            try {
                const unitAccounts = await this.unitAccountApi.getAccountsByCustomerId(customer.unitCustomer.id, {
                    excludeClosedAccounts: false
                });
                allMemberAccounts = unitAccounts.allMemberAccounts;
            } catch (ignore) {
                allMemberAccounts = [];
            }
            accounts = await Promise.all(
                allMemberAccounts.map(async account => {
                    const finAccount = {
                        customerId,
                        tags: account.attributes.tags as AccountTags,
                        unitAccount: account as DepositAccount
                    };
                    return await this.create(finAccount);
                })
            );
        }
        return accounts;
    }
}
