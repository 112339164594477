import {
    Application,
    ApplicationDocument,
    CreateBusinessApplicationRequest,
    CreateIndividualApplicationRequest,
    Customer,
    CustomerToken
} from '@unit-finance/unit-node-sdk';
import { ObjectId } from 'bson';
import { isNil, omitBy, pickBy } from 'lodash';
import AccountApi from '../api/unit/account.api';
import ApplicationApi from '../api/unit/application.api';
import CustomerApi from '../api/unit/customer.api';
import { FintechApplication, FintechApplicationDTO, FintechCustomer, FintechCustomerDTO } from '../dal/models/customer';
import { FintechCustomerDataService, WithId } from '../types/dal-types';
import {
    ApiContext,
    CustomerTokenPurposeEnum,
    FintechCustomerToken,
    UnitVerificationTokenChannelEnum,
    ApplicationTags,
    CustomerTags,
    ApplicationStatusEnum,
    CustomerStatusEnum
} from '../types';
import { logger } from '@finance/shared';
import { PersistenceError } from '../dal/persistence-error';
import { PlatformError } from '../errors';
import { nowISO } from '../utils';
import { getDataStore } from '../dal/data-store';

export class CustomerDataService {
    private static readonly legalCustomerTags = Object.keys({
        memberId: '',
        hbAccountId: ''
    } as CustomerTags);

    readonly dataService: FintechCustomerDataService<FintechCustomerDTO>;
    readonly unitAccountApi: AccountApi;
    readonly unitApplicationApi: ApplicationApi;
    readonly unitCustomerApi: CustomerApi;

    constructor({
        dataService,
        unitAccountApi,
        unitApplicationApi,
        unitCustomerApi
    }: {
        dataService: FintechCustomerDataService<FintechCustomerDTO>;
        unitAccountApi?: AccountApi;
        unitApplicationApi?: ApplicationApi;
        unitCustomerApi?: CustomerApi;
    }) {
        this.dataService = dataService;

        // Do not cache axios calls for this class, as we need to make sure we always get the latest data.
        // For example, when application is in awaiting documents state, we need to make sure we get the latest
        // application status from Unit, as it is approved in background.
        const axiosFactoryProps = { cacheAxios: false };
        this.unitAccountApi = unitAccountApi ?? new AccountApi(undefined, axiosFactoryProps);
        this.unitApplicationApi = unitApplicationApi ?? new ApplicationApi(undefined, axiosFactoryProps);
        this.unitCustomerApi = unitCustomerApi ?? new CustomerApi(undefined, axiosFactoryProps);

        // Bind the function to the instance of the class or withApplicationStatus will fail to find 'this' when trying to read.
        this.withApplicationStatus = this.withApplicationStatus.bind(this);
    }

    static finCustomerToFinCustomerDao = (finCustomer: FintechCustomer): FintechCustomerDTO => {
        return {
            _id: finCustomer._id ? new ObjectId(finCustomer._id) : undefined,
            unitCustomerId: finCustomer.unitCustomer?.id,
            tags: pickBy(finCustomer.tags, (value, key) => CustomerDataService.legalCustomerTags.includes(key)) as unknown as CustomerTags,
            application: CustomerDataService.finApplicationToFinApplicationDao(finCustomer.application),
            counterpartyRelationships: finCustomer.counterpartyRelationships ? [...finCustomer.counterpartyRelationships] : undefined
        };
    };

    static finCustomerDaoToFinCustomer = async ({
        finCustomer,
        unitCustomerApi,
        unitApplicationApi
    }: {
        finCustomer: WithId<FintechCustomerDTO>;
        unitCustomerApi: CustomerApi;
        unitApplicationApi: ApplicationApi;
    }): Promise<FintechCustomer> => {
        const customer = {
            _id: finCustomer._id.toHexString(),
            unitCustomer: finCustomer.unitCustomerId
                ? ((await unitCustomerApi.getCustomerById(finCustomer.unitCustomerId)) as Customer)
                : undefined,
            tags: { ...finCustomer.tags },
            application: await CustomerDataService.finApplicationDaoToFinApplication(
                <WithId<FintechApplicationDTO> | null>finCustomer.application,
                unitApplicationApi
            ),
            counterpartyRelationships: finCustomer.counterpartyRelationships ? [...finCustomer.counterpartyRelationships] : undefined
        };

        // In case this method is called before we receive the customer.created event from Unit, but the application
        // entity does have a customer relationship, fill it in here
        if (!customer.unitCustomer && customer.application?.unitApplication?.relationships?.customer?.data?.id) {
            customer.unitCustomer = (await unitCustomerApi.getCustomerById(
                customer.application.unitApplication.relationships.customer.data.id
            )) as Customer;

            // Update DB accordingly
            if (customer.unitCustomer) {
                logger.info({ finCustomer, unitCustomerId: customer.unitCustomer.id }, 'Update missing unitCustomerId in DB');
                const dtoRef =
                    ((await getDataStore().customerDataService.dataService.read(finCustomer._id)) as WithId<FintechCustomerDTO>) ??
                    finCustomer;
                dtoRef.unitCustomerId = customer.unitCustomer.id;
                await getDataStore().customerDataService.dataService.update(dtoRef);
            }
        }

        return customer;
    };

    static finApplicationToFinApplicationDao = (finApplication?: FintechApplication): FintechApplicationDTO | undefined => {
        return finApplication
            ? {
                  _id: finApplication.unitApplication?.id,
                  status: finApplication.status ? { ...finApplication.status } : undefined
              }
            : undefined;
    };

    static finApplicationDaoToFinApplication = async (
        finApplication: WithId<FintechApplicationDTO> | null,
        unitApplicationApi: ApplicationApi
    ): Promise<FintechApplication | undefined> => {
        return finApplication
            ? {
                  unitApplication: finApplication._id ? ((await unitApplicationApi.get(finApplication._id)) as Application) : undefined,
                  status: finApplication.status ? { ...finApplication.status } : undefined
              }
            : undefined;
    };

    set context(context: ApiContext) {
        this.unitAccountApi.setContext(context);
        this.unitApplicationApi.setContext(context);
        this.unitCustomerApi.setContext(context);
    }

    /**
     * When unit application is created we will create a customer entity in our database.
     * @param entity A customer entity to persist.
     * @param applicationType The type of application to create.
     * @param attributes The attributes to pass to the application creation request.
     * @returns The persisted customer entity, with `id` field set.
     */
    async create(
        entity: FintechCustomer,
        applicationType?: 'individualApplication' | 'businessApplication',
        attributes?: Partial<CreateIndividualApplicationRequest['attributes']> | Partial<CreateBusinessApplicationRequest['attributes']>
    ): Promise<WithId<FintechCustomer>> {
        // Make sure we do not create a duplicate customer.
        const existingFinCustomer = await this.findCustomerByHbMemberId(entity.tags.memberId);

        // The id is used as application tag, hence we must create it first
        if (existingFinCustomer) {
            entity._id = existingFinCustomer._id;
        } else {
            const finCustomerDb = await this.dataService.create(CustomerDataService.finCustomerToFinCustomerDao(entity));
            entity._id = finCustomerDb?._id.toHexString();
        }

        if (!existingFinCustomer?.application?.unitApplication && applicationType && attributes) {
            entity = await this.createUnitApplication(entity, applicationType, attributes);
        }

        return <WithId<FintechCustomer>>entity;
    }

    private async createUnitApplication(
        entity: FintechCustomer,
        applicationType: 'individualApplication' | 'businessApplication',
        attributes: Partial<CreateIndividualApplicationRequest['attributes']> | Partial<CreateBusinessApplicationRequest['attributes']>
    ): Promise<WithId<FintechCustomer>> {
        const tags = {
            ...entity.tags,
            fintechCustomerId: entity._id
        };

        let unitApplication: Application | undefined;
        if (applicationType === 'individualApplication') {
            const res = await this.unitApplicationApi.newApplication({
                type: applicationType,
                attributes: {
                    ...attributes,
                    tags
                }
            } as CreateIndividualApplicationRequest);
            unitApplication = res?.data;
        } else {
            const res = await this.unitApplicationApi.newApplication({
                type: applicationType,
                attributes: {
                    ...attributes,
                    tags
                }
            } as CreateBusinessApplicationRequest);
            unitApplication = res?.data;
        }

        // Make sure application tags contains the fintech customer id
        if (unitApplication) {
            unitApplication.attributes.tags = {
                ...unitApplication.attributes.tags,
                fintechCustomerId: entity._id
            };
        }

        if (entity.application) {
            entity.application.unitApplication = unitApplication;
        } else {
            entity.application = { unitApplication };
        }

        // Update application statuses
        const finApplication: FintechApplication = entity.application;
        const status = finApplication.status ?? {};
        status.applicationRequestedDate = new Date();
        status.applicationRequestsCount = (status.applicationRequestsCount ?? 0) + 1;
        finApplication.status = status;

        // Update the entity with the application now.
        return await this.update(entity);
    }

    /**
     * Delete the `customer.application.unitApplication` field, so customer can apply again.
     * @param id Customer identifier in DB.
     */
    async cancelApplication(id: string): Promise<WithId<FintechCustomer> | null> {
        const finCustomer = await this.read(id);
        if (finCustomer?.application?.unitApplication) {
            finCustomer.application.unitApplication = undefined;
            finCustomer.application.status = {
                ...finCustomer.application.status,
                applicationCanceledDate: new Date()
            };
            return await this.update(finCustomer);
        }
        return finCustomer;
    }

    async delete(id: string) {
        const customer = await this.read(id);
        if (customer) {
            // We do not delete existing Unit customers, but only denied applications.
            // If a Unit customer was already created, use `close` API instead, for archiving it.
            if (customer.unitCustomer) {
                throw new PlatformError({
                    message: 'Cannot delete a customer with an existing Unit customer resource',
                    obj: { id }
                });
            }

            const unitApplication = customer.application?.unitApplication;
            if (unitApplication?.attributes.status === ApplicationStatusEnum.Approved) {
                throw new PlatformError({
                    message: 'Cannot delete a customer with an approved application',
                    obj: { id }
                });
            }

            if (unitApplication) {
                await this.unitApplicationApi.update({
                    applicationId: unitApplication.id,
                    applicationType: unitApplication.type,
                    tags: {
                        accountNumber: null,
                        routingNumber: null,
                        hbAccountId: null,
                        memberId: null,
                        comment: 'Customer deleted by admin',
                        lastUpdatedAt: nowISO()
                    } as unknown as ApplicationTags
                });
            }

            return await this.dataService.delete(new ObjectId(id));
        }

        return false;
    }

    async deleteMany(ids: string[]) {
        return await this.dataService.deleteMany(ids.map(id => new ObjectId(id)));
    }

    async read(id: string): Promise<WithId<FintechCustomer> | null> {
        const finCustomer = await this.dataService.read(new ObjectId(id));

        if (finCustomer) {
            return <WithId<FintechCustomer>>await CustomerDataService.finCustomerDaoToFinCustomer({ finCustomer, ...this });
        }

        return null;
    }

    async readAll() {
        const finCustomersDb = await this.dataService.readAll();
        if (finCustomersDb?.length) {
            const finCustomers = await Promise.all(
                finCustomersDb.map(finCustomer => CustomerDataService.finCustomerDaoToFinCustomer({ finCustomer, ...this }))
            );
            return <WithId<FintechCustomer>[]>finCustomers;
        }

        return [];
    }

    private async withApplicationStatus(entity: FintechCustomer) {
        // Only relevant for updates. If there is no _id, it is redirected to #create.
        if (entity._id && entity.application?.status) {
            const existingStatus = (await this.read(entity._id))?.application?.status;
            entity.application.status = {
                ...(existingStatus ? existingStatus : {}),
                ...omitBy(entity.application.status, isNil)
            };
        }
        return entity;
    }

    async update(entity: FintechCustomer) {
        let finCustomerDb: WithId<FintechCustomerDTO> | null;
        const daoEntity = CustomerDataService.finCustomerToFinCustomerDao(await this.withApplicationStatus(entity));
        if (entity._id) {
            finCustomerDb = await this.dataService.update(daoEntity);
        } else {
            finCustomerDb = await this.dataService.create(daoEntity);
        }

        entity._id = finCustomerDb?._id.toHexString();
        return <WithId<FintechCustomer>>entity;
    }

    async findCustomerByHbMemberId(hbMemberId: string) {
        const finCustomer = await this.dataService.findCustomerByHbMemberId(hbMemberId);

        if (finCustomer) {
            return <WithId<FintechCustomer>>await CustomerDataService.finCustomerDaoToFinCustomer({ finCustomer, ...this });
        }

        return null;
    }

    async findCustomerByUnitCustomerId(customerId: string) {
        const finCustomer = await this.dataService.findCustomerByUnitCustomerId(customerId);

        if (finCustomer) {
            return <WithId<FintechCustomer>>await CustomerDataService.finCustomerDaoToFinCustomer({ finCustomer, ...this });
        }

        // Temporary migration, until there is no FintechCustomer in DB with unitCustomerId==null.
        const unitCustomer = await this.unitCustomerApi.getCustomerById(customerId);
        if (unitCustomer) {
            const finCustomer = await this.findOrCreateFintechCustomer((unitCustomer.attributes.tags as CustomerTags).memberId);
            if (finCustomer) {
                return finCustomer;
            }
        }

        return null;
    }

    /**
     * Helper method used to find existing fintech customer or create a new one if it does not exist.
     * We rely on HB member identifier to find or create and autofill the fintech customer.
     * If fintech customer does not exist, but application does, it means a user has been created before DB was enabled. In
     * this case we get the application, unit customer, and create the fintech customer representation with them.
     * @param hbMemberId HB member identifier, available in session.
     * @returns Fintech customer, or null if not found and there is no application to create one from. (pre-kyc)
     */
    async findOrCreateFintechCustomer(hbMemberId: string) {
        let fintechCustomer = await this.findCustomerByHbMemberId(hbMemberId);
        let application: Application | null = null;
        if (!fintechCustomer) {
            application = await this.unitApplicationApi.getApplicationByMemberId(hbMemberId, { shouldFilterCancelled: true });
            logger.debug({ hbMemberId, application }, 'Fintech customer not found, trying to create one');

            // If application was created without fintech customer (before DB was enabled), create fintech customer now.
            if (application) {
                fintechCustomer = await this.createMissingFintechCustomer(application);
            } else {
                logger.debug(
                    {
                        hbMemberId
                    },
                    'Cannot create fintech customer without application'
                );
            }
        }

        fintechCustomer = await this.resolveMissingData(fintechCustomer);

        logger.debug(
            {
                hbMemberId,
                fintechCustomerId: fintechCustomer?._id,
                unitApplicationId: fintechCustomer?.application?.unitApplication?.id,
                unitCustomerId: fintechCustomer?.unitCustomer?.id
            },
            'Fintech customer found'
        );
        return fintechCustomer;
    }

    async addCounterpartyRelationships(customerId: string, relationships: string[]): Promise<FintechCustomer> {
        const customer = await this.findCustomerByUnitCustomerId(customerId);
        if (!customer) {
            throw new PersistenceError(`Failed to find customer with ID: ${customerId}`);
        }

        const existingRelationships = customer.counterpartyRelationships ?? [];
        const updatedRelationships = new Set<string>([...existingRelationships, ...relationships]);
        customer.counterpartyRelationships = [...updatedRelationships];
        return await this.update(customer);
    }

    async removeCounterpartyRelationships(customerId: string, relationships: string[]): Promise<FintechCustomer> {
        const customer = await this.findCustomerByUnitCustomerId(customerId);
        if (!customer) {
            throw new PersistenceError(`Failed to find customer with ID: ${customerId}`);
        }

        const existingRelationships = customer.counterpartyRelationships ?? [];
        const relationshipsSet = new Set(relationships);
        customer.counterpartyRelationships = existingRelationships.filter((relationship: string) => !relationshipsSet.has(relationship));
        return await this.update(customer);
    }

    async archive(id: string) {
        const customer = await this.read(id);
        if (customer) {
            logger.info({ customer }, 'archiving customer');
            await this.cancelApplication(id);
            const unitCustomer = customer.unitCustomer;
            if (unitCustomer && unitCustomer.attributes.status !== CustomerStatusEnum.Archived) {
                await this.unitCustomerApi.archiveCustomer(unitCustomer.id);
            }
            return customer;
        }
        return null;
    }

    async createTokenVerification(customerId: string, channel: UnitVerificationTokenChannelEnum) {
        return await this.unitCustomerApi.createTokenVerification(customerId, channel);
    }

    async createCustomerToken({
        customerId,
        verificationToken,
        verificationCode,
        purpose,
        fingerprint,
        buildResponse
    }: {
        customerId: string;
        verificationToken: string;
        verificationCode: string;
        purpose: CustomerTokenPurposeEnum;
        fingerprint?: string;
        buildResponse?: boolean;
    }): Promise<CustomerToken | FintechCustomerToken> {
        const customerToken = await this.unitCustomerApi.createCustomerToken({
            customerId,
            verificationToken,
            verificationCode,
            purpose,
            fingerprint
        });

        if (buildResponse) {
            return this.unitCustomerApi.buildCustomerTokenResponse(purpose, customerToken);
        }

        return customerToken;
    }

    async getExistingCustomerToken(tokenPurpose: CustomerTokenPurposeEnum): Promise<FintechCustomerToken | null> {
        const customerToken = await this.unitCustomerApi.getExistingCustomerToken(tokenPurpose);
        return this.unitCustomerApi.buildCustomerTokenResponse(tokenPurpose, customerToken);
    }

    async listAwaitingDocuments(applicationId: string): Promise<ApplicationDocument[]> {
        return await this.unitApplicationApi.listDocuments(applicationId);
    }

    async getDocumentsUploadToken(): Promise<string> {
        return await this.unitApplicationApi.getUploadToken();
    }

    private async createMissingFintechCustomer(application: Application) {
        const applicationTags = application.attributes.tags as ApplicationTags;
        if (!applicationTags) {
            logger.debug({ application }, 'Application has no tags');
            return null;
        }

        const fintechCustomerEntity: FintechCustomer = {
            application: { unitApplication: application },
            tags: {
                hbAccountId: applicationTags.hbAccountId,
                memberId: applicationTags.memberId
            }
        };

        const customerId = application.relationships.customer?.data.id;
        if (customerId) {
            const customer = await this.unitCustomerApi.getCustomerById(customerId);

            if (customer) {
                await this.enrichCustomer(fintechCustomerEntity, application, customer);
            }
        }

        return await this.create(fintechCustomerEntity);
    }

    private async enrichCustomer(fintechCustomerEntity: FintechCustomer, application: Application, customer: Customer) {
        this.updateApplicationStatus(fintechCustomerEntity, application, customer);
        fintechCustomerEntity.unitCustomer = customer;
    }

    private updateApplicationStatus(fintechCustomerEntity: FintechCustomer, application: Application, customer: Customer) {
        (fintechCustomerEntity.application as FintechApplication).status = {
            applicationRequestedDate: new Date(application.attributes.createdAt ?? Date.now()),
            applicationApprovedDate: new Date(customer.attributes.createdAt ?? Date.now()),
            applicationRequestsCount: 1
        };
    }

    /**
     * Fills in historically missing data in Unit.
     * This method resolves the missing data and updates the database accordingly.
     * @param fintechCustomer Fintech customer entity to be verified
     * @return The up-to-date entity
     */
    private async resolveMissingData(fintechCustomer: WithId<FintechCustomer> | null): Promise<WithId<FintechCustomer> | null> {
        try {
            let anyChange = false;
            if (fintechCustomer?.tags?.memberId && fintechCustomer.application?.unitApplication?.relationships?.customer?.data?.id) {
                if (!fintechCustomer.unitCustomer) {
                    const unitCustomer = await this.unitCustomerApi.getCustomerById(
                        fintechCustomer.application.unitApplication.relationships.customer.data.id
                    );
                    if (unitCustomer) {
                        anyChange = true;
                        fintechCustomer.unitCustomer = unitCustomer as Customer;
                    }
                }

                if (!fintechCustomer.tags.hbAccountId && fintechCustomer.unitCustomer) {
                    anyChange = true;
                    fintechCustomer.tags.hbAccountId = (fintechCustomer.unitCustomer.attributes.tags as CustomerTags)?.hbAccountId;
                }
            }

            if (anyChange) {
                return await this.update(fintechCustomer as FintechCustomer);
            }
        } catch (error) {
            logger.error({ fintechCustomer, error }, 'Failed resolving missing data');
        }

        return fintechCustomer;
    }
}
