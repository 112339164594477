import { ObjectId } from 'bson';
import { isNil, omitBy } from 'lodash';
import { Collection, Condition, Db, Filter, OptionalUnlessRequiredId, UpdateFilter } from 'mongodb';
import { FintechEntity } from '../../models';
import { PersistenceError } from '../../persistence-error';
import { FintechDataService, WithId } from '../../../types/dal-types';
import { MongoCollectionsEnum } from '../../../types';

export abstract class AbstractMongoDataService<Entity extends FintechEntity = FintechEntity> implements FintechDataService<Entity> {
    readonly collection: Collection<Entity>;

    protected constructor(db: Db) {
        this.collection = db.collection<Entity>(this.getCollectionName());
    }

    protected abstract getCollectionName(): MongoCollectionsEnum;

    private extractId(objectId: string | ObjectId): ObjectId {
        if (typeof objectId === 'string') {
            return new ObjectId(objectId);
        }

        return objectId;
    }

    async create(entity: Entity) {
        const result = await this.collection.insertOne(<OptionalUnlessRequiredId<Entity>>entity);

        if (result?.insertedId) {
            if (entity._id !== result.insertedId) {
                entity._id = this.extractId(result.insertedId);
            }

            return <WithId<Entity>>(<unknown>entity);
        }

        throw new PersistenceError(`Failed to create entity: ${typeof entity}`, [entity]);
    }

    async delete(id: ObjectId) {
        const result = await this.collection.deleteOne(<Filter<Entity>>{
            _id: id
        });
        return result.deletedCount === 1;
    }

    async read(id: ObjectId) {
        return <WithId<Entity>>(<unknown>await this.collection.findOne(<Filter<Entity>>{ _id: id }));
    }

    async readAll() {
        return <WithId<Entity>[]>(<unknown>await this.collection.find().toArray());
    }

    async update(entity: Entity) {
        // To support partial updates, without forcing users to fill all properties, we omit all null/undefined values.
        const result = await this.collection.updateOne(<Filter<Entity>>{ _id: entity._id }, { $set: <Entity>omitBy(entity, isNil) });

        if (result?.matchedCount === 1) {
            return <WithId<Entity>>(<unknown>entity);
        }

        throw new PersistenceError(`Failed to update entity: ${typeof entity}`, [entity]);
    }

    async deleteMany(ids: ObjectId[]) {
        const result = await this.collection.deleteMany(<Filter<Entity>>{ _id: <Condition<ObjectId>>{ $in: ids } });
        return result.deletedCount;
    }

    private arrayToUnsetUpdateFilter(arr: string[]) {
        const dictionary: UpdateFilter<Entity> = {};
        arr.forEach(element => {
            dictionary[element] = '';
        });
        return dictionary;
    }

    async unset(id: ObjectId, fields: string[]) {
        const updateFilter = this.arrayToUnsetUpdateFilter(fields);
        const result = await this.collection.updateOne(<Filter<Entity>>{ _id: id }, { $unset: updateFilter });
        return result.modifiedCount === 1;
    }
}
