import {
    Account,
    AccountListParams,
    Application,
    ApplicationListParams,
    Card,
    CardListParams,
    CounterpartyListParams,
    Customer,
    CustomersListParams,
    EventListParams,
    Payment,
    PaymentListParams,
    Statement,
    StatementsListParams,
    Transaction,
    TransactionListParams,
    Unit,
    UnitError,
    UnitEvent,
    AchCounterparty,
    Dispute,
    DisputeListParams
} from '@unit-finance/unit-node-sdk';
import UnitBase, { WithAccessControlParams } from './base.api';
import platformConfig from '../../config/platformConfig';
import { AxiosFactoryProps } from '@finance/shared/dist/utils/axios-factory';
import { ApiContext } from '../../types';
import { logger } from '@finance/shared';

/**
 * A type to easily list all Unit APIs that define a `list` method, of specific unit model.
 */
export type IterableApiName = keyof Pick<
    Unit,
    | 'accounts'
    | 'applications'
    | 'cards'
    | 'counterparties'
    | 'customers'
    | 'events'
    | 'payments'
    | 'statements'
    | 'transactions'
    | 'disputes'
>;

export default abstract class UnitIterableApi<Model extends UnitModel, ListParams extends UnitListParams> extends UnitBase {
    constructor(context?: ApiContext, axiosFactoryProps: Omit<AxiosFactoryProps, 'config'> = {}) {
        super(context, axiosFactoryProps);
    }

    protected abstract get apiName(): IterableApiName;

    async list(params: ListParams & WithAccessControlParams): Promise<Model[]> {
        const api = await this.withAccessControl(params);
        return api[this.apiName]
            .list(params)
            .then(response => response.data as Model[])
            .catch((error: UnitError) => {
                throw this.handleUnitError({ error });
            });
    }

    /**
     * Stream models from Unit, in batches, for specified parameters.
     * This method is useful when you need to process many models, but don't want to keep them all in memory, as we
     * fetch them in with pagination.
     * @param params - `list` API params.
     * @param modelHandler - will be called for each batch. Its return value determines if we need to continue or break up.
     * @param logData - additional attributes to add to log messages
     */
    async stream(
        params: ListParams & { limit?: number; offset?: number },
        modelHandler: (models: Model[]) => Promise<boolean>,
        logData: object = {}
    ) {
        const limit = params.limit ?? platformConfig.unit.defaultStreamBatchSize;
        let offset = params.offset || 0;
        let hasNext = true;
        const stats = {
            batchesProcessed: 0,
            batchesSucceeded: 0,
            batchesFailed: 0,
            limitInEachBatch: limit
        };
        logData = {
            ...logData,
            apiImpl: this.constructor.name
        };

        while (hasNext) {
            stats.batchesProcessed++;
            let models: Model[];
            try {
                logger.debug({ ...logData, offset }, 'Listing resources');
                models = await this.list({ ...params, limit, offset });
            } catch (error) {
                stats.batchesFailed++;
                logger.error({ ...logData, error, offset }, 'Error while listing resources for stream batch');
                throw error;
            }

            let shouldContinue = true;
            try {
                shouldContinue = await modelHandler(models);
            } catch (error) {
                stats.batchesFailed++;
                logger.error({ ...logData, error, offset }, 'Error while handling stream batch');
            }

            hasNext = shouldContinue && models.length > 0;
            offset += models.length;
            stats.batchesSucceeded++;
        }

        logger.debug({ ...logData, stats }, 'Stream ended');
    }
}

/*
 * type is omitted as sometimes it's undefined|string[] and sometimes undefined|string,
 * so TS loses it. We don't need it anyway in UnitIterableApi, and we can still use it
 * in the underlying API types, seamlessly.
 * Same for `query`.
 */
type UnitListParams = Omit<
    | AccountListParams
    | ApplicationListParams
    | CardListParams
    | CounterpartyListParams
    | CustomersListParams
    | DisputeListParams
    | EventListParams
    | PaymentListParams
    | StatementsListParams
    | TransactionListParams,
    'type' | 'query'
>;

type UnitModel = Account | Application | Card | AchCounterparty | Customer | UnitEvent | Payment | Statement | Transaction | Dispute;
