import { ServerClient } from 'postmark';
import sharedConfig from '../config/sharedConfig';
import logger from './logger';

type TEmailWithTemplate = {
    from: string;
    to: string;
    templateAlias: EmailTemplateAlias;
    templateModel: object;
};

export type EmailTemplateAlias = 'finance-customer-created' | 'kyc-upload-documents' | 'transfer-email';

export const TEST_EMAIL_ADDRESS = 'test@honeybook.com';
let instance: EmailClient;
export function emailClient(): EmailClient {
    if (!instance) {
        instance = new EmailClient();
    }
    return instance;
}
export default emailClient;

export class EmailClient {
    private client: ServerClient;

    constructor() {
        this.client = new ServerClient(sharedConfig.postmark.token);
    }

    sendEmailWithTemplate({ from, to, templateAlias, templateModel }: TEmailWithTemplate) {
        const isFinanceProd = sharedConfig.env.isProductionEnv;
        const extendedTemplateModel = {
            ...templateModel,
            product_url: 'https://www.honeybook.com',
            product_name: 'HoneyBook Finance',
            company_name: 'HoneyBook Inc',
            company_address: '539 Bryant Street, Suite 200, San Francisco, CA 94107'
        };

        logger.debug({ from, to, templateAlias }, 'Sending email with template');
        return this.client
            .sendEmailWithTemplate({
                From: from,
                To: isFinanceProd ? to : TEST_EMAIL_ADDRESS,
                TemplateAlias: templateAlias,
                TemplateModel: extendedTemplateModel,
                Tag: templateAlias,
                MessageStream: 'finance-app'
            })
            .catch(err => {
                logger.error({ from, to, templateAlias, err }, 'Error sending email');
            });
    }
}
