import { AbstractInMemoryDataService } from './abstract-in-memory-data-service';
import { FintechPlaidInstitutionAuthDataService, WithId } from '../../../types/dal-types';
import { logger } from '@finance/shared/dist/utils/logger';
import { PlaidInstitutionAuth } from '../../models';
import { PlaidInstitutionAuthStatus } from '../../../types';

export class PlaidInstitutionAuthInMemoryDataService
    extends AbstractInMemoryDataService<PlaidInstitutionAuth>
    implements FintechPlaidInstitutionAuthDataService<PlaidInstitutionAuth>
{
    public constructor(storage: Record<string, PlaidInstitutionAuth>) {
        super(storage);
        logger.debug({ storage }, 'InMemoryDB: PlaidInstitutionAuthInMemoryDataService.new');
    }

    async readAllValidInstitutionAuths(): Promise<WithId<PlaidInstitutionAuth>[]> {
        const institutionAuths = Object.values(this.storage).filter(institutionAuth =>
            [PlaidInstitutionAuthStatus.PendingData, PlaidInstitutionAuthStatus.Ok].includes(institutionAuth.status)
        );
        logger.debug(
            {
                institutionAuths,
                storage: this.storage
            },
            'InMemoryDB: readAllValidInstitutionAuths'
        );
        return <WithId<PlaidInstitutionAuth>[]>[...institutionAuths];
    }

    async findPlaidInstitutionAuthByItemId(itemId: string): Promise<PlaidInstitutionAuth | null> {
        const institutionAuth = Object.values(this.storage).find(institutionAuth => {
            return institutionAuth.itemId === itemId;
        });
        logger.debug(
            {
                itemId,
                institutionAuth,
                knownItemIds: Object.values(this.storage).map(institutionAuth => institutionAuth.itemId),
                storage: this.storage
            },
            'InMemoryDB: findPlaidInstitutionAuthByItemId'
        );

        return <WithId<PlaidInstitutionAuth> | null>{ ...institutionAuth };
    }

    async findPlaidInstitutionAuthsByHbMemberId(hbMemberId: string): Promise<WithId<PlaidInstitutionAuth>[]> {
        const institutionAuths = Object.values(this.storage).filter(
            institutionAuth => institutionAuth.hbMemberId.toHexString() === hbMemberId
        );
        logger.debug(
            {
                hbMemberId,
                institutionAuths,
                knownMemberIds: Object.values(this.storage).map(institutionAuth => institutionAuth.hbMemberId.toHexString()),
                storage: this.storage
            },
            'InMemoryDB: findPlaidInstitutionAuthsByHbMemberId'
        );
        return <WithId<PlaidInstitutionAuth>[]>[...institutionAuths];
    }

    async findPlaidInstitutionAuthByHbMemberIdAndInstitutionId(
        hbMemberId: string,
        institutionId: string
    ): Promise<WithId<PlaidInstitutionAuth> | null> {
        const institutionAuth = Object.values(this.storage).find(institutionAuth => {
            return institutionAuth.hbMemberId.toHexString() === hbMemberId && institutionAuth.institutionId === institutionId;
        });
        logger.debug(
            {
                hbMemberId,
                institutionId,
                institutionAuth,
                knownMemberIds: Object.values(this.storage).map(institutionAuth => institutionAuth.hbMemberId.toHexString()),
                storage: this.storage
            },
            'InMemoryDB: findPlaidInstitutionAuthByHbMemberIdAndInstitutionId'
        );

        return <WithId<PlaidInstitutionAuth> | null>{ ...institutionAuth };
    }
}
