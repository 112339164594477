export enum EventType {
    AppLoaded = 'finance-app-loaded',
    AppRendered = 'finance-app-rendered',
    SignOut = 'finance-app-sign-out',
    ModalInteraction = 'finance-app-modal',
    ToastInteraction = 'finance-app-toast'
}

type PostMessagePayload = Record<string, unknown>;
type PostMessageArgs = {
    event: EventType;
    payload?: PostMessagePayload;
};

export const postMessage = ({ event, payload }: PostMessageArgs) => {
    window.parent.postMessage(
        {
            event,
            payload
        },
        '*'
    );
};

export const appLoadedMessage = (success: boolean) => {
    postMessage({
        event: EventType.AppLoaded,
        payload: {
            success
        }
    });
};

export const appRenderedMessage = () => {
    postMessage({
        event: EventType.AppRendered
    });
};

export const signOutMessage = () => {
    postMessage({
        event: EventType.SignOut
    });
};

export const modalInteractionMessage = ({ isOpen }: { isOpen: boolean }) => {
    postMessage({
        event: EventType.ModalInteraction,
        payload: {
            isOpen
        }
    });
};

export const toastInteractionMessage = ({ isOpen }: { isOpen: boolean }) => {
    postMessage({
        event: EventType.ToastInteraction,
        payload: {
            isOpen
        }
    });
};
