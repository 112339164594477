import { FintechTaxEntityDTO } from '../../models/tax-entity';
import { AbstractInMemoryDataService } from './abstract-in-memory-data-service';
import { FintechTaxEntityDataService, WithId } from '../../../types/dal-types';
import { logger } from '@finance/shared/dist/utils/logger';

export class TaxEntityInMemoryDataService
    extends AbstractInMemoryDataService<FintechTaxEntityDTO>
    implements FintechTaxEntityDataService<FintechTaxEntityDTO>
{
    public constructor(storage: Record<string, FintechTaxEntityDTO>) {
        super(storage);
        logger.debug({ storage }, 'InMemoryDB: TaxEntityInMemoryDataService.new');
    }

    async findTaxEntityByHbMemberId(hbMemberId: string): Promise<WithId<FintechTaxEntityDTO>[]> {
        const taxEntities = Object.values(this.storage).filter(taxEntity => taxEntity.hbMemberId.toHexString() === hbMemberId);
        logger.debug(
            {
                hbMemberId,
                taxEntities,
                knownMemberIds: Object.values(this.storage).map(taxEntity => taxEntity.hbMemberId.toHexString()),
                storage: this.storage
            },
            'InMemoryDB: findTaxEntityByHbMemberId'
        );
        return <WithId<FintechTaxEntityDTO>[]>taxEntities;
    }

    async findTaxEntityByHbTaxEntityId(hbTaxEntityId: string): Promise<WithId<FintechTaxEntityDTO> | null> {
        const taxEntity = Object.values(this.storage).find(taxEntity => taxEntity.hbTaxEntityId.toHexString() === hbTaxEntityId);
        logger.debug(
            {
                hbTaxEntityId,
                taxEntity,
                knownHbTaxEntityIds: Object.values(this.storage).map(taxEntity => taxEntity.hbTaxEntityId.toHexString()),
                storage: this.storage
            },
            'InMemoryDB: findTaxEntityByHbTaxEntityId'
        );
        if (taxEntity) {
            return <WithId<FintechTaxEntityDTO>>taxEntity;
        }
        return null;
    }
}
