/* eslint-disable */
import Script from 'next/script';
import { useEffect } from 'react';
import { getSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import getConfig from 'next/config';

const {
    publicRuntimeConfig: { intercomAppId: appId }
} = getConfig();

type IntercomOptions = {
    app_id: string;
    email?: string;
    user_id?: string;
};

function boot({ app_id, email, user_id }: IntercomOptions) {
    // @ts-ignore
    Intercom('boot', {
        app_id,
        email,
        user_id
    });
}

export const update = () => {
    // @ts-ignore
    Intercom('update');
};

export const shutdown = () => {
    // @ts-ignore
    Intercom('shutdown');
};

export const startTourWithId = (id: number) => {
    // @ts-ignore
    Intercom('startTour', id);
};

export const showNewMessage = () => {
    // @ts-ignore
    window.Intercom?.('showNewMessage');
};

export type IntercomFunc = (operation: string, param?: any) => void;

declare global {
    interface Window {
        Intercom: IntercomFunc;
    }
}

/*
    IntercomProvider sets up the Intercom function on the window object, making it globally accessible.
 */
const IntercomProvider = () => {
    const router = useRouter();

    useEffect(() => {
        // @ts-ignore
        window.intercomSettings = {
            app_id: appId
        };
        getSession().then(session => {
            if (session) {
                boot({
                    app_id: appId,
                    email: session.user.email,
                    user_id: session.user._id
                });
            } else {
                shutdown();
            }
        });

        router.events.on('routeChangeStart', update);

        return () => {
            router.events.off('routeChangeStart', update);
        };
    }, [router.events]);

    return (
        <Script
            id="intercom"
            dangerouslySetInnerHTML={{
                __html: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${appId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`
            }}
        />
    );
};

export default IntercomProvider;
