import { FintechEventDataService, WithId } from '../types/dal-types';
import { FintechEventDTO, FintechEvent, FintechEventAttributes, FintechEventAttributesDTO } from '../dal/models/event';
import { ObjectId } from 'bson';

export class EventDataService {
    readonly dataService: FintechEventDataService<FintechEventDTO>;

    constructor({ dataService }: { dataService: FintechEventDataService<FintechEventDTO> }) {
        this.dataService = dataService;
    }

    static finEventToFinEventDto = (finEvent: FintechEvent): FintechEventDTO => {
        return {
            _id: finEvent._id ? new ObjectId(finEvent._id) : undefined,
            type: finEvent.type,
            source: finEvent.source,
            attributes: EventDataService.finEventAttributesToFinEventAttributesDto(finEvent.attributes),
            relationships: finEvent.relationships
        };
    };

    static finEventDtoToFinEvent = (finEvent: FintechEventDTO): FintechEvent => {
        return {
            _id: finEvent._id ? finEvent._id.toHexString() : undefined,
            type: finEvent.type,
            source: finEvent.source,
            attributes: EventDataService.finEventAttributesDtoToFinEventAttributes(finEvent.attributes),
            relationships: finEvent.relationships
        };
    };

    private static finEventAttributesToFinEventAttributesDto = (finEventAttributes: FintechEventAttributes): FintechEventAttributesDTO => {
        return {
            ...finEventAttributes,
            createdAt: new Date(finEventAttributes.createdAt)
        };
    };

    private static finEventAttributesDtoToFinEventAttributes = (finEventAttributes: FintechEventAttributesDTO): FintechEventAttributes => {
        return {
            ...finEventAttributes,
            createdAt: finEventAttributes.createdAt.toISOString()
        };
    };

    async create(entity: FintechEvent): Promise<WithId<FintechEvent>> {
        const finEventDto = EventDataService.finEventToFinEventDto(entity);
        const createdFinEventDto = await this.dataService.create(finEventDto);
        entity._id = createdFinEventDto?._id.toHexString();
        return <WithId<FintechEvent>>entity;
    }

    async read(id: string): Promise<FintechEvent | null> {
        const finEventDto = await this.dataService.read(new ObjectId(id));
        return finEventDto ? EventDataService.finEventDtoToFinEvent(finEventDto) : null;
    }

    async update(entity: FintechEvent): Promise<WithId<FintechEvent>> {
        let finEvent: WithId<FintechEventDTO> | null;
        const finEventDto = EventDataService.finEventToFinEventDto(entity);
        if (entity._id) {
            finEvent = await this.dataService.update(finEventDto);
        } else {
            finEvent = await this.dataService.create(finEventDto);
        }
        entity._id = finEvent?._id.toHexString();
        return <WithId<FintechEvent>>entity;
    }

    async findEventsByType(eventType: string): Promise<WithId<FintechEvent>[]> {
        const result = await this.dataService.findEventsByType(eventType);
        return result.map(finEvent => <WithId<FintechEvent>>EventDataService.finEventDtoToFinEvent(finEvent));
    }

    async findEventsByTimeRange(since: Date, until: Date): Promise<WithId<FintechEvent>[]> {
        const result = await this.dataService.findEventsByTimeRange(since, until);
        return result.map(finEvent => <WithId<FintechEvent>>EventDataService.finEventDtoToFinEvent(finEvent));
    }
}
