import { AbstractInMemoryDataService } from './abstract-in-memory-data-service';
import { FintechEventDataService, WithId } from '../../../types/dal-types';
import { FintechEventDTO } from '../../models/event';

export class EventInMemoryDataService extends AbstractInMemoryDataService<FintechEventDTO> implements FintechEventDataService<FintechEventDTO> {
    public constructor(storage: Record<string, FintechEventDTO> = {}) {
        super(storage);
    }

    async findEventsByType(eventType: string): Promise<WithId<FintechEventDTO>[]> {
        const result = Object.values(this.storage).filter(event => event.type === eventType);
        return <WithId<FintechEventDTO>[]>result;
    }

    async findEventsByTimeRange(since: Date, until: Date): Promise<WithId<FintechEventDTO>[]> {
        const result = Object.values(this.storage).filter(event => {
            const eventDate = event.attributes.createdAt;
            return eventDate >= since && eventDate < until;
        });
        return <WithId<FintechEventDTO>[]>result;
    }
}
