import { ApolloProvider } from '@apollo/client';
import { SessionProvider } from 'next-auth/react';
import { StatsigProvider } from 'providers/StatsigProvider';
import { ToastProvider } from 'providers/ToastProvider';
import VGSProvider from 'components/VGSProvider';
import IntercomProvider from 'providers/IntercomProvider';
import UnitComponentsProvider from 'providers/UnitComponentsProvider';
import { TestIdBoundaryProvider } from '@honeybook/hbui';
import ModalProvider from 'providers/ModalProvider';
import FullStory from 'lib/client/FullStory';
import useApollo from 'apollo-client';
import ErrorBoundary from 'components/ErrorBoundary';
import { Session } from 'next-auth';
import { GlobalStyles } from 'styles/globals';
import SegmentProvider from 'providers/SegmentProvider';
import RouteListener from 'lib/client/RouteListener';
import DatadogRumInit from 'lib/client/datadogRum';
import TwoFactorAuthProvider from 'components/two-factor/TwoFactorAuthProvider';

const GlobalProviders = ({ children, session }: { children: React.ReactNode; session: Session }) => {
    const apolloClient = useApollo();

    return (
        <ErrorBoundary>
            <VGSProvider />
            <UnitComponentsProvider />
            <GlobalStyles />
            <RouteListener />
            <DatadogRumInit />
            <ToastProvider>
                <SessionProvider session={session} refetchInterval={30 * 60} refetchOnWindowFocus={true}>
                    <StatsigProvider>
                        <IntercomProvider />
                        <FullStory />
                        <ApolloProvider client={apolloClient}>
                            <TestIdBoundaryProvider prefix={'finance'}>
                                <SegmentProvider>
                                    <TwoFactorAuthProvider>
                                        <ModalProvider>{children}</ModalProvider>
                                    </TwoFactorAuthProvider>
                                </SegmentProvider>
                            </TestIdBoundaryProvider>
                        </ApolloProvider>
                    </StatsigProvider>
                </SessionProvider>
            </ToastProvider>
        </ErrorBoundary>
    );
};

export default GlobalProviders;
