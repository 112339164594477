export const LoginEvents = {
    clickLogout: 'finance app logout'
};

export const DashboardEvents = {
    loadedDashboard: 'finance dashboard',
    clickDocuments: 'finance app download document',
    clickActions: 'finance app actions',
    clickTransaction: 'finance app transaction',
    downloadDocumentsMenu: 'finance app download documents menu',
    loadTransactionDetails: 'finance app transaction detail',
    clickShowNumber: 'finance app show number',
    clickHideNumber: 'finance app hide number',
    clickShowCardModal: 'finance app show card',
    authFormLoaded: 'finance app load auth form',
    authSubmitted: 'finance app auth form submit',
    authModalClose: 'finance app auth form close',
    transactionSelectCategory: 'transaction select category',
    transactionSelectProject: 'transaction select project',
    openExpenseProject: 'finance app open expense project',
    txFeedEmptyStateOrderCardClick: 'finance app transaction feed empty state order card'
};

export const SetupListEvents = {
    listLoaded: 'finance app setup list',
    clickCollapseExpand: 'finance app setup list collapse',
    clickListItem: 'finance app setup list item',
    clickHide: 'finance app setup list not interested'
};

export const CardModalEvents = {
    clickCopyButton: 'finance app copy card number',
    modalClose: 'finance app close card modal',
    clickFreezeCard: 'finance app freeze card',
    clickUnfreezeCard: 'finance app unfreeze card',
    bannerShown: 'finance app update payment method banner',
    updatePaymentMethod: 'finance app update payment method'
};

export const CancelCardModalEvents = {
    modalLoaded: 'finance app open cancel card modal',
    modalClosed: 'finance app close cancel card modal',
    clickCancelCard: 'finance app cancel card'
};

export const CreateCardModalEvents = {
    modalLoaded: 'finance app open create card modal',
    modalClosed: 'finance app close create card modal',
    createCard: 'finance app create card'
};

export const ActivateCardEvents = {
    clickActivateCard: 'finance app open activation card modal',
    successActivateCard: 'finance app activate card',
    errorActivateCard: 'finance app activate card'
};

export const DocumentsEvents = {
    loadModal: 'finance app document download modal',
    clickDownload: 'finance app confirm document download',
    clickPreview: 'finance app preview document download'
};

export const SmartTaxEvents = {
    clickLoadModal: 'smart tax modal',
    loadedModal: 'smart tax modal',
    clickCloseModal: 'close smart tax modal',
    clickEditCalculationField: 'smart tax edit field',
    clickCalculate: 'smart tax calculate'
};

export const ExpenseTaggingEvent = {
    loadedMain: 'expense detected',
    clickSelectCategoryCTA: 'expense go to categories',
    loadedCategoryPicker: 'expense category picker',
    clickCategorySelected: 'expense category selected',
    clickSeeMore: 'expense see more categories',
    clickEditCategory: 'expense edit category',
    clickSaveCTA: 'expense save',
    loadedSummary: 'expense summary',
    clickDoneCTA: 'expenses done viewing',
    clickHelpCenter: 'expenses help center',
    clickEmptyExpenseWidgetPreview: 'expense widget animation'
};

export const TaxWithholdingEvents = {
    clickStartTaxWithholding: 'tax withholding load modal',
    loadModal: 'tax withholding modal',
    closeModal: 'tax withholding close modal',
    clickOptIn: 'tax withholding opt in',
    successOptIn: 'tax withholding opt in',
    clickActions: 'tax withholding actions',
    clickOptOut: 'tax withholding request opt out',
    loadOptOutModal: 'tax withholding opt out modal',
    clickConfirmOptOut: 'tax withholding confirm opt out',
    clickDiscardOptOut: 'tax withholding discard opt out',
    successOptOut: 'tax withholding opt out',
    clickWithdraw: 'tax withholding request withdrawal',
    loadWithdrawModal: 'tax withholding withdrawal modal',
    clickConfirmWithdraw: 'tax withholding confirm withdrawal',
    clickDiscardWithdraw: 'tax withholding discard withdrawal',
    successWithdraw: 'tax withholding withdrawal'
};

export const TransferMoneyEvents = {
    clickTransferMoney: 'finance app transfer money',
    loadModal: 'finance app transfer money modal',
    clickCloseModal: 'close transfer money modal',
    clickConfirmTransferMoney: 'confirm detail transfer money modal',
    clickSubmitTransferMoney: 'submit transfer money modal',
    startNewRecurringTransfer: 'finance start new recurring transfer',
    toggleRecurringTransferStatus: 'finance toggle recurring transfer status',
    closeRecurringModal: 'finance close recurring transfer money modal',
    confirmCreateRecipient: 'finance confirm create recurring transfer',
    learnMoreRecurringTransfers: 'finance learn more recurring transfers'
};

export const TransferMoneyEventsWithRecipient = {
    loadSelectRecipient: 'finance app transfer money select recipient',
    recipientDetails: 'finance app transfer money recipient details',
    loadRecipientDetails: 'finance app transfer money recipient details',
    successRecipientDetails: 'finance app transfer money recipient details',
    errorRecipientDetails: 'finance app transfer money recipient details',
    clickAddRecipientDropdown: 'finance app transfer money add recipient from dropdown',
    clickBackStep: 'finance app transfer money back',
    clickNextStep: 'finance app transfer money next',
    closeModal: 'finance app close transfer money modal with recipient',
    successTransferMoney: 'finance app transfer money',
    errorTransferMoney: 'finance app transfer money',
    useCardBannerLoad: 'finance app transfer money use card banner',
    useCardBannerLinkClick: 'finance app transfer money use card banner link'
};

export const KYCEvents = {
    pageLoaded: 'finance kyc',
    backClick: 'finance kyc back',
    closeClick: 'finance kyc close',
    nextClick: 'finance kyc next',
    beneficialOwnerAdded: 'finance kyc add beneficial owner',
    beneficialOwnerRemoved: 'finance ky cremove beneficial owner',
    beneficialOwnerRemoveConfirm: 'finance kyc confirm removal of beneficial owner',
    businessCheckbox: 'finance kyc business information certification checkbox',
    formSubmitted: 'finance kyc submit',
    agreementCheckbox: 'finance kyc agreement checkbox',
    completed: 'finance kyc completed',
    error: 'finance kyc error',
    kyc: 'finance kyc',
    beneficialOwnerNeeded: 'finance kyc beneficial owner detail',
    entityTypeSelected: 'finance kyc entity type selected'
};

export const ApplicationEvents = {
    pageLoaded: 'finance kyc upload documents',
    attachmentClick: 'finance kyc upload document',
    submitDocuments: 'finance kyc submit uploaded documents',
    uploadSuccess: 'finance kyc upload documents',
    uploadError: 'finance kyc document send failure',
    invalidFileSize: 'finance kyc document size exceeds limit'
};

export const WelcomeEvents = {
    pageLoaded: 'finance welcome page',
    clickCTA: 'finance welcome cta click',
    faqClick: 'finance welcome faq click'
};

export const SavingPercentageBucketEvents = {
    modalLoaded: 'saving percentage bucket modal',
    confirmClick: 'saving percentage bucket confirm',
    closeClick: 'saving percentage bucket close modal'
};

export const ManualTransferBucketEvents = {
    modalLoaded: 'manual transfer bucket modal',
    confirmClick: 'manual transfer bucket confirm',
    closeClick: 'manual transfer bucket close modal',
    success: 'manual transfer bucket',
    error: 'manual transfer bucket'
};

export const CreateSavingsBucketEvents = {
    modalLoaded: 'create savings bucket modal',
    confirmClick: 'create savings bucket confirm',
    closeClick: 'create savings bucket close modal',
    success: 'create savings bucket',
    error: 'create savings bucket'
};

export const EditSavingsBucketEvents = {
    modalLoaded: 'edit savings bucket modal',
    confirmClick: 'edit savings bucket confirm',
    closeClick: 'edit savings bucket close modal',
    success: 'edit savings bucket',
    error: 'edit savings bucket'
};
export const DeleteSavingsBucketEvents = {
    modalLoaded: 'delete savings bucket modal',
    confirmClick: 'delete savings bucket confirm',
    closeClick: 'delete savings bucket close modal',
    success: 'delete savings bucket',
    error: 'delete savings bucket'
};

export const SavingsBucketEvents = {
    actionClick: 'savings bucket menu action'
};

export const FreezeSavingsBucketEvents = {
    modalLoaded: 'stop saving modal',
    confirmClick: 'stop saving confirm',
    closeClick: 'stop saving close modal',
    success: 'stop saving for bucket',
    error: 'stop saving for bucket'
};

export const WithdrawBucketSavingsEvents = {
    modalLoaded: 'move savings to balance modal',
    confirmClick: 'move savings to balance confirm',
    closeClick: 'move savings to balance close modal',
    success: 'move savings to balance',
    error: 'move savings to balance'
};

export const ProductTourEvents = {
    tourStarted: 'tour started'
};

export const CashflowInsightEvents = {
    balanceDetailsClick: 'finance cashflow details',
    modalLoaded: 'finance cashflow details modal',
    modalClosed: 'finance cashflow details close modal',
    timeframeDropdown: 'finance cashflow timeframe dropdown',
    timeframeDropdownSelect: 'finance cashflow select timeframe'
};

export const OverviewEvents = {
    loaded: 'finance overview',
    taxesCTAClick: 'finance overview taxes cta',
    cardCTAClick: 'finance overview card cta'
};

export const HBPaymentsWidgetEvents = {
    paymentsLink: 'finance hb-payments-widget payments link'
};

export const HBPaymentsSnapshotEvents = {
    paymentsLink: 'finance hb-payments-snapshot payments link'
};

export const OverviewFeaturePromoBannerEvents = {
    moreInfoLink: 'finance overview feature promo banner more info link',
    closeBanner: 'finance overview feature promo banner close'
};

export const OverviewPromoteBalanceEvents = {
    learnMore: 'finance overview balance banner learn more',
    applyNow: 'finance overview balance banner apply now',
    videoImage: 'finance overview balance banner video image',
    videoModal: 'finance overview balance banner video modal',
    watchedVideo: 'finance overview balance banner watched most video',
    closeVideoModal: 'finance overview balance banner close video modal'
};
export const PlaidEvents = {
    connectAccount: 'finance plaid connect account',
    success: 'finance plaid success',
    reconnect: 'finance plaid reconnect',
    exchangePublicTokenError: 'finance plaid exchange public token error',
    linkTokenError: 'finance plaid create link token error',
    close: 'finance plaid close'
};

export const ExternalAccountsMenuEvents = {
    open: 'finance external accounts menu open',
    rename: 'finance external accounts menu rename',
    delete: 'finance external accounts menu delete',
    deleteModal: 'finance external accounts menu delete modal',
    viewDetails: 'finance external accounts menu view details',
    loadDetailsModal: 'finance external accounts menu details modal',
    detailsModalChangeTimeframe: 'finance external accounts menu details modal change timeframe'
};

export const ExternalAccountsPopoverEvents = {
    open: 'finance external accounts popover open',
    dismiss: 'finance external accounts popover dismiss',
    connectAccount: 'finance external accounts popover connect account'
};

export const ExternalInstitutionDeleteModalEvents = {
    modalLoaded: 'finance external institution delete modal',
    confirm: 'finance external institution delete confirm',
    close: 'finance external institution delete close modal',
    success: 'finance external institution delete',
    error: 'finance external institution delete'
};

export const WhitelistEvents = {
    pageLoaded: 'finance white list page',
    whiteListCTAClick: 'finance white list cta',
    videoClick: 'finance white list video'
};

export const MWebEvents = {
    overviewPage: 'finance mweb overview page',
    kycPage: 'finance mweb kyc page',
    dashboardPage: 'finance mweb dashboard page',
    openApp: 'finance mweb open app',
    overviewLearnMore: 'finance mweb overview learn more',
    balanceFAQ: 'finance mweb balance faq'
};

export const YearlyRevenueGoalEvents = {
    loadWidget: 'yearly revenue goal',
    clickNewGoalCta: 'yearly revenue goal set new goal cta',
    loadSetGoalModal: 'yearly revenue goal set goal modal',
    submitGoal: 'yearly revenue goal set goal modal cta',
    closeSetGoalModal: 'yearly revenue goal close set goal modal',
    clickMenuEditGoal: 'yearly revenue goal menu edit goal',
    clickMenuDeleteGoal: 'yearly revenue goal menu delete goal',
    loadReachedGoalModal: 'yearly revenue goal reached goal modal',
    clickReachedGoalModalCTA: 'yearly revenue goal reached goal modal cta',
    clickReachedGoalBannerCTA: 'yearly revenue goal reached goal banner cta',
    loadDeleteGoalModal: 'yearly revenue goal delete goal modal',
    clickCloseDeleteGoalModal: 'yearly revenue goal delete goal modal close',
    clickDeleteGoalCTA: 'yearly revenue goal delete goal modal cta',
    popoverLoad: 'yearly revenue goal popover',
    popoverClose: 'yearly revenue goal popover close'
};
