import {
    Customer,
    CustomersListParams,
    PatchCustomerRequest,
    UnitError,
    CreateTokenVerificationRequest,
    UnitScope,
    CustomerToken
} from '@unit-finance/unit-node-sdk';
import UnitIterableApi, { IterableApiName } from './unit.iterable.api';
import platformConfig from '../../config/platformConfig';
import PlatformError from '../../errors/platform-error';
import UserTokenError from '../../errors/user-token-error';
import { CustomerTokenPurposeEnum, UnitVerificationTokenChannelEnum, ApiContext, FintechCustomerToken, CustomerTags } from '../../types';
import { logger, redis } from '@finance/shared';
import { getCustomerTokenKey } from '../../utils';
import { AxiosFactoryProps } from '@finance/shared/dist/utils/axios-factory';

interface IUnitTokenScopeConfig {
    scope: UnitScope[];
    expiresIn: number;
    exposedToClient: boolean;
}

const unitTokenPurposeMap: Record<CustomerTokenPurposeEnum, IUnitTokenScopeConfig> = {
    [CustomerTokenPurposeEnum.ReadCardDetails]: {
        scope: ['cards-sensitive'],
        expiresIn: 3600,
        exposedToClient: true
    },
    [CustomerTokenPurposeEnum.TransferMoney]: {
        scope: ['payments-write', 'accounts', 'transactions', 'statements'],
        expiresIn: platformConfig.unit.tokenTTL,
        exposedToClient: false
    },
    [CustomerTokenPurposeEnum.FullPrivileges]: {
        scope: [
            'accounts',
            'cards',
            'cards-sensitive',
            'cards-sensitive-write',
            'cards-write',
            'customers',
            'payments-write',
            'statements',
            'transactions',
            'counterparties',
            'counterparties-write'
        ],
        expiresIn: platformConfig.unit.tokenTTL,
        exposedToClient: true
    }
};

export default class CustomerApi extends UnitIterableApi<Customer, CustomersListParams> {
    constructor(context?: ApiContext, axiosFactoryProps: Omit<AxiosFactoryProps, 'config'> = {}) {
        super(context, axiosFactoryProps);
    }

    async getCustomerById(customerId: string): Promise<Customer | null> {
        if (!customerId) {
            // Error and not FinanceError, to avoid duplicate log.
            const error = new Error('Unable to get customer by falsy id');
            logger.debug({ context: this.context, error }, 'Tried to get customer by falsy id');
            return null;
        }

        const api = await this.withAccessControl({ customerId });
        return api.customers
            .get(customerId)
            .then(customer => customer.data)
            .catch(err => {
                this.handleUnitError({ error: err as UnitError });
                return null;
            });
    }

    async getCustomerByMemberId(memberId: string): Promise<Customer> {
        if (!memberId) {
            throw new PlatformError({
                message: 'HoneyBook Member ID is required to get Unit customer by member ID'
            });
        }

        try {
            return (
                await this.list({
                    customerId: this.context?.customerId,
                    tags: { memberId }
                })
            ).find(customer => (customer?.attributes?.tags as CustomerTags).memberId === memberId) as Customer;
        } catch (ignore) {
            // For backward compatibility of the listCustomers method that used to return
            // an empty array in case of error.
            return undefined as unknown as Customer;
        }
    }

    async getExistingCustomerToken(tokenPurpose: CustomerTokenPurposeEnum): Promise<CustomerToken | null> {
        const fingerprint = this.context?.user?.fingerprint as string;
        const customerId = this.context?.customerId as string;
        logger.trace({ tokenPurpose, customerId }, 'checking for existing token for customer');
        const key = getCustomerTokenKey(customerId, fingerprint, tokenPurpose);
        const token = await redis.get(key);
        if (!token) {
            return null;
        }
        return {
            type: 'customerBearerToken',
            attributes: {
                token,
                expiresIn: await redis.ttl(key)
            }
        };
    }

    async createTokenVerification(customerId: string, channel: UnitVerificationTokenChannelEnum) {
        try {
            // hack until we solve FIN-278
            const unitChannel = channel.toLowerCase() as CreateTokenVerificationRequest['attributes']['channel'];
            const api = await this.withAccessControl({ customerId });
            const resp = await api.customerToken.createTokenVerification(customerId, {
                type: 'customerTokenVerification',
                attributes: {
                    channel: unitChannel
                }
            });
            return resp.data.attributes.verificationToken;
        } catch (err) {
            if ((err as UnitError).isUnitError) {
                this.handleUnitError({ error: err as UnitError, customerId, channel });
            }

            throw err;
        }
    }

    isTokenExposedToClient(tokenPurpose: CustomerTokenPurposeEnum) {
        return unitTokenPurposeMap[tokenPurpose].exposedToClient;
    }

    buildCustomerTokenResponse(purpose: CustomerTokenPurposeEnum, token: CustomerToken | null): FintechCustomerToken {
        const isMasked = !this.isTokenExposedToClient(purpose);

        return {
            isMasked: !!token && isMasked,
            token: isMasked ? undefined : token?.attributes.token,
            expiresIn: token?.attributes.expiresIn
        };
    }

    async createCustomerToken({
        customerId,
        verificationToken,
        verificationCode,
        purpose,
        fingerprint
    }: {
        customerId: string;
        verificationToken: string;
        verificationCode: string;
        purpose: CustomerTokenPurposeEnum;
        fingerprint?: string;
    }): Promise<CustomerToken> {
        fingerprint = fingerprint ?? this.context?.user?.fingerprint;
        if (!fingerprint) {
            throw new UserTokenError('fingerprint');
        }

        const tokenCacheKey = getCustomerTokenKey(customerId, fingerprint, purpose);
        logger.info({ tokenCacheKey }, 'creating token for tokenCacheKey');

        const { scope, expiresIn } = unitTokenPurposeMap[purpose];

        try {
            const api = await this.withAccessControl({ customerId });
            const resp = await api.customerToken.createToken(customerId, {
                type: 'customerToken',
                attributes: {
                    scope: scope.join(' '),
                    verificationCode,
                    verificationToken,
                    expiresIn
                }
            });

            const { data: customerToken } = resp;
            const {
                attributes: { token: userToken, expiresIn: tokenExpiresIn }
            } = customerToken;
            logger.trace({ tokenCacheKey }, 'caching token for tokenCacheKey');
            redis.setEx(tokenCacheKey, tokenExpiresIn, userToken);
            return customerToken;
        } catch (err) {
            if ((err as UnitError).isUnitError) {
                throw this.handleUnitError({ error: err as UnitError, customerId });
            }
            throw err;
        }
    }

    async updateCustomer(customerUpdateRequest: PatchCustomerRequest) {
        const { customerId } = customerUpdateRequest;

        try {
            const api = await this.withAccessControl({ customerId });
            return await api.customers.update(customerUpdateRequest);
        } catch (err) {
            logger.error(
                {
                    customerId
                },
                "Couldn't updated unit customer"
            );

            throw err;
        }
    }

    async archiveCustomer(customerId: string) {
        try {
            const api = this.dangerouslyWithAdminToken();
            return await api.customers.archiveCustomer({
                customerId,
                data: {
                    type: 'archiveCustomer',
                    attributes: {
                        reason: 'Inactive'
                    }
                }
            });
        } catch (err) {
            if ((err as UnitError).isUnitError) {
                throw this.handleUnitError({ error: err as UnitError, customerId });
            }
            throw err;
        }
    }

    protected get apiName(): IterableApiName {
        return 'customers';
    }
}
