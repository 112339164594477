import { FintechCustomerDTO } from '../../models/customer';
import { AbstractInMemoryDataService } from './abstract-in-memory-data-service';
import { FintechCustomerDataService, WithId } from '../../../types/dal-types';
import { logger } from '@finance/shared/dist/utils/logger';

export class CustomerInMemoryDataService extends AbstractInMemoryDataService<FintechCustomerDTO> implements FintechCustomerDataService<FintechCustomerDTO> {
    public constructor(storage: Record<string, FintechCustomerDTO>) {
        super(storage);
        logger.debug({ storage }, 'InMemoryDB: CustomerInMemoryDataService.new');
    }

    async findCustomerByHbMemberId(hbMemberId: string): Promise<WithId<FintechCustomerDTO> | null> {
        const customer = Object.values(this.storage).find(customer => customer.tags.memberId === hbMemberId);
        logger.debug(
            {
                hbMemberId,
                customer,
                knownHbMemberIds: Object.values(this.storage).map(customer => customer.tags.memberId),
                storage: this.storage
            },
            'InMemoryDB: findCustomerByHbMemberId'
        );
        if (customer) {
            return <WithId<FintechCustomerDTO>>customer;
        }
        return null;
    }

    async findCustomerByUnitCustomerId(unitCustomerId: string): Promise<WithId<FintechCustomerDTO> | null> {
        const customer = Object.values(this.storage).find(customer => customer.unitCustomerId === unitCustomerId);
        logger.debug(
            {
                unitCustomerId,
                customer,
                knownUnitCustomerIds: Object.values(this.storage).map(customer => customer.unitCustomerId),
                storage: this.storage
            },
            'InMemoryDB: findCustomerByUnitCustomerId'
        );
        if (customer) {
            return <WithId<FintechCustomerDTO>>customer;
        }
        return null;
    }
}
